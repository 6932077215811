/* outline issue */
/* /core/libraries/slickSlider/assets/1.4.1/slick.css */
/* /core/libraries/slickSlider/assets/1.6.1/slick.css */
.slick-list:focus {
	outline: 1px dotted #5f5f5f;
}

/* color contrast issue */
/* /core/libraries/jquery.mmenu/assets/5.6.5/css/jquery.mmenu.all.css */
.mm-menu .mm-search input {
	background: #ffffff;
	color: #757575;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-list-container ul.fly-out-menu-list li a:focus,
.fly-out-menu-list-container ul.fly-out-menu-list li a:hover {
	background: #1f78d1;
}

.fly-out-menu-list-container ul.fly-out-menu-list li a:active,
.fly-out-menu-list-container ul.fly-out-menu-list li.selected a {
	background: #1f78d1 !important;
}

/* /core/public/shared/assets/css/screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #757575;
	color: #ffffff;
}

/* /core/public/shared/assets/css/uitags.css */
.ui-form-legend h3 .ui-form-legend-step {
	background: #ffffff;
	color: #757575;
}

/* relative units issue */
/* /core/libraries/jquery.mmenu/assets/5.6.5/css/jquery.mmenu.all.css */
.mm-listview {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.mm-listview .mm-divider {
	font-size: 0.625rem;
	line-height: 1.5625rem;
}

em.mm-counter {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.mm-search input {
	font-size: 0.875rem;
	line-height: 1.625rem;
}

.mm-noresultsmsg {
	font-size: 1.3125rem;
}

.mm-indexer {
	font-size: 0.75rem;
}

/* /core/libraries/magnificPopup/assets/1.0.0/magnific-popup.css */
.mfp-counter {
	font-size: 0.75rem;
	line-height: 1.125rem;
}

.mfp-figure small {
	font-size: 0.75rem;
	line-height: 0.875rem;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-container .fly-out-menu-button .glyph-keyboard_arrow_down {
	font-size: 1.25rem;
}

/* /core/public/shared/assets/css/uitags.css */
.ui-form-legend {
	font-size: 1.0625rem;
}

.ui-form-note-extra {
	font-size: 0.6875rem;
}

/* /core/website/modules/submissionforms/css/print.css */
.module-forms.confirmation .step-title {
	font-size: 0.875rem;
}

/* /core/website/modules/submissionforms/css/screen.css */
#submissionforms_module.cms_form .cms_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms.confirmation .step-title {
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms .ui-form-field-payment-information,
.module-forms .ui-data-addresses h3 {
	font-size: 0.875rem;
}
