body {
	font-family: Roboto, sans-serif;
	color: #424242;
	background-color: #ffffff;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 400;
}

#printHeader,
#printFooter {
	display: none;
}

h1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 2.5rem;
	line-height: 3.2rem;
	font-weight: 400;
}

h2 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 2rem;
	line-height: 2.7rem;
	font-weight: 400;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 1.75rem;
	line-height: 2.45rem;
	font-weight: 400;
}

h4 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 1.5rem;
	line-height: 2.2rem;
	font-weight: 400;
}

h5 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 1.25rem;
	line-height: 1.95rem;
	font-weight: 400;
}

h6 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 1.15rem;
	line-height: 1.75rem;
	font-weight: 400;
}

p {
	margin-bottom: 1rem;
	font-size: 1.05rem;
	line-height: 1.7rem;
}

a {
	text-decoration: underline;
}

ul {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	padding-left: 1.25rem;
	font-size: 1.05rem;
	line-height: 1.7rem;
}

ol {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	padding-left: 1.25rem;
	font-size: 1.05rem;
	line-height: 1.7rem;
}

label {
	display: block;
	margin-bottom: 5px;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-weight: 700;
}

blockquote {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding: 1rem 1.25rem;
	border-left: 5px solid #c9353c;
	background-color: #f1f1ef;
	font-family: 'Roboto Slab', sans-serif;
	color: #2e2e2e;
	font-size: 1.15rem;
	line-height: 1.85rem;
}

.button,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	display: inline-block;
	min-width: 100px;
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	padding: 9px 1rem;
	border: 1px solid #c9353c;
	border-radius: 2px;
	background-color: #c9353c;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1rem;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
}

.primary,
.secondary,
.tertiary,
button[type="submit"] {
	padding: 9px 1rem !important;
	border: 1px solid #c9353c !important;
}

.button:hover {
	border-color: #ad1e30;
	background-color: #ad1e30;
}

.primary:hover,
.secondary:hover,
.tertiary:hover,
button[type="submit"]:hover {
	border-color: #ad1e30 !important;
	background-color: #ad1e30 !important;
}

.button.secondary,
.secondary,
button[type="submit"].secondary {
	background-color: #fff;
	color: #424242;
	text-align: center;
}

.button.secondary:hover {
	background-color: #d3d3d3;
	opacity: 0.75;
	color: #000;
}

.secondary:hover,
button[type="submit"].secondary:hover {
	background-color: #d3d3d3 !important;
	opacity: 0.75;
	color: #000 !important;
}

.button.search-button {
	width: 30%;
	height: 2.5rem;
	min-height: 2.5rem;
	margin-top: 0rem;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding: 0.38rem 1rem;
	float: left;
}

.button.sidebar-button,
.right-side-col .primary,
.right-side-col .secondary,
.right-side-col .tertiary,
.right-side-col button[type="submit"],
.left-bar-custom .primary,
.left-bar-custom .secondary,
.left-bar-custom .tertiary,
.left-bar-custom button[type="submit"] {
	height: 36px;
	min-width: 0px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 0.35rem 0.75rem;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col .primary,
.right-side-col .secondary,
.right-side-col .tertiary,
.right-side-col button[type="submit"],
.left-bar-custom .primary,
.left-bar-custom .secondary,
.left-bar-custom .tertiary,
.left-bar-custom button[type="submit"] {
	padding: 0.35rem 0.75rem !important;
}

.button.shop-now-button {
	margin-bottom: 0rem;
	padding: 12px 1.25rem;
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	font-size: 1.125rem;
	letter-spacing: 0.05rem;
}

.button.shop-now-button:hover {
	padding-right: 3rem;
	background-color: #fa173d;
	background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.button.shop-now-button:active {
	background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.button.cb-flex-button {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	min-width: 0px;
	margin: 16px 1.875rem 32px;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease;
	cursor: pointer;
}

.button.cb-flex-button:hover {
	margin-right: 1.68rem;
	margin-left: 1.68rem;
	background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.button.cb-flex-button:active {
	background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.button.tertiary,
.tertiary,
button[type="submit"].tertiary {
	padding: 0.35rem 0.75rem;
	border-color: #424242;
	background-color: #fff;
	color: #424242;
	line-height: 1.4rem;
	font-weight: 400;
}

.tertiary,
button[type="submit"].tertiary {
	padding: 0.35rem 0.75rem !important;
	border-color: #424242 !important;
}

.button.tertiary:hover {
	padding: 0.35rem 0.75rem;
	background-color: #d3d3d3;
}

.tertiary:hover,
button[type="submit"].tertiary:hover {
	background-color: #d3d3d3 !important;
	border-color: #424242 !important;
}

.button.recipe-button {
	height: 38px;
	margin-top: 0rem;
	margin-bottom: 0rem;
	margin-left: 1rem;
	font-size: 0.85rem;
	letter-spacing: 0.02rem;
}

.button.shopping-list-button {
	height: 38px;
	margin-top: 0.5rem;
	margin-bottom: 0rem;
	font-size: 0.85rem;
	letter-spacing: 0.02rem;
}

.main-content-wrapper {
	width: 100%;
	margin-top: 0rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	float: left;
}

.main-content-wrapper.right-sidebar {
	width: calc( 80% - 2rem );
}

.main-content-wrapper.cards-main-content-wrapper {
	padding-top: 2rem;
	padding-bottom: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.main-content-wrapper.cards-main-content-wrapper.recipe-cards-main-content-wrap {
	padding-top: 2rem;
}

.container {
	max-width: 1700px;
	margin-right: auto;
	margin-left: auto;
}

@media screen and (min-width: 1921px) {
	.rotator-section .container {
		max-width: initial;
	}
}

.container.inside-page-content-container {
	display: block;
	max-width: 1300px;
	min-height: 500px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.container.inside-page-content-container.page-title-container {
	position: relative;
	min-height: 0px;
}

.container.footer-container {
	display: block;
	max-width: none;
	padding: 35px 0 0 0;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: #000;
	background-image: none;
	background-size: auto;
}

.container.signoff-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	padding: 1rem 2rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #141414;
}

.container.homepage-flex-content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1700px;
	padding: 2rem 5%;
}

.container.homepage-flex-content-container.callout-container {
	max-width: none;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	background-position: 0px 0px;
	background-size: auto;
}

.container.homepage-flex-content-container.lower-row-container {
	max-width: none;
	padding: 0rem 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 4px solid #fff;
	background-color: #fff;
}

.left-nav-col {
	width: 20%;
	max-width: 270px;
	min-width: 190px;
	margin-top: -2px;
	margin-right: 3%;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.inside-content-column {
	/* width: 80%; */
	width: 77%;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.inside-content-column.right-sidebar-only-content-column {
	width: 100%;
}

.inside-content-column.no-sidebars-content-column {
	width: 100%;
	margin-right: 10%;
	margin-left: 10%;
}

.breadcrumbs-wrapper {
	background-color: #fff;
	font-family: Roboto, sans-serif;
	font-size: 0.9rem;
	line-height: 1.5rem;
}

.breadcrumb-link {
	padding-right: 0.25rem;
	font-size: 0.9rem;
	line-height: 1.5rem;
}

.breadcrumb-current {
	display: inline-block;
	color: #c9353c;
}

.content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.right-side-col {
	position: relative;
	width: 20%;
	min-width: 150px;
	margin-top: 2.5rem;
	margin-left: 2rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.right-side-col h2 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.right-side-col p {
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 300;
}

.right-side-col a {
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 300;
}

.right-side-col h3 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.right-side-col h4 {
	margin-bottom: 0.5rem;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.left-nav-wrapper {
	position: relative;
}

.left-nav-section-title {
	height: 51px;
	margin-top: 0rem;
	margin-bottom: 0rem;
	font-size: 1.5rem;
	text-decoration: none;
}

.left-nav-section-title-link {
	display: block;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 0rem;
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	font-size: 1.25rem;
	line-height: 2.2rem;
	font-weight: 300;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-list {
	margin-bottom: 1rem;
	padding-top: 52px;
	color: #424242;
}

.left-nav-list.subnav-list {
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-top: 0px;
}

.left-nav-list-item {
	margin-bottom: 0rem;
}

.left-nav-list-item.grandchild-li {
	margin-left: 1rem;
}

.left-nav-list-item.greatgrandchild-li {
	margin-left: 2rem;
	font-size: 0.9rem;
}

.left-nav-link {
	display: block;
	padding: 0.4rem 0.75rem 0.4rem 0.85rem;
	background-image: url('../images/arrow-medium.svg');
	background-position: -20px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #424242;
	font-size: 1rem;
	line-height: 1.7rem;
	text-decoration: none;
}

.left-nav-link:hover {
	background-image: url('../images/arrow-medium.svg');
	background-position: 0px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	-webkit-transform: translate(6px, 0px);
	-ms-transform: translate(6px, 0px);
	transform: translate(6px, 0px);
	color: #c9353c;
}

.left-nav-link.selected,
.left-nav-link.w--current {
	margin-left: 0rem;
	background-position: 0px 50%;
	-webkit-transition: none 0ms ease;
	transition: none 0ms ease;
	color: #c9353c;
}

.left-nav-link.selected:hover,
.left-nav-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link.grandchild-link {
	font-size: 0.95rem;
}

.left-nav-link.grandchild-link.selected,
.left-nav-link.grandchild-link.w--current {
	margin-left: 0rem;
	background-position: 0px 50%;
	-webkit-transition: none 0ms ease;
	transition: none 0ms ease;
	color: #c9353c;
}

.left-nav-link.grandchild-link.selected:hover,
.left-nav-link.grandchild-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link.greatgrandchild-link {
	font-size: 0.85rem;
	line-height: 1.4rem;
}

.header-section {
	position: fixed;
	z-index: 1000;
	width: 100%;
	top: 0;
}

.navbar {
	background-color: #fff;
	background-image: var(--dark-wood-horizontal-bg);
	background-position: 0px 0px;
}

.brand {
	display: block;
	width: 172px;
	height: 138px;
	min-width: 172px;
	margin-top: -91px;
	margin-right: 10px;
	margin-left: 10px;
	padding-right: 10px;
	padding-left: 10px;
	float: none;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	cursor: pointer;
}

.brand.w--current {
	min-width: 0px;
}

.brand.mobile-brand {
	display: none;
}

.nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	float: none;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: transparent;
	box-shadow: none;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-align: center;
}

.nav-menu.nav-right {
	max-width: 560px;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 560px;
	-ms-flex-preferred-size: 560px;
	flex-basis: 560px;
}

.nav-menu.nav-left {
	max-width: 560px;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 560px;
	-ms-flex-preferred-size: 560px;
	flex-basis: 560px;
}

.nav-menu.mobile-nav-menu {
	display: none;
}

.main-nav-link {
	position: relative;
	z-index: 900;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 4px;
	margin-left: 4px;
	padding: 0.75rem 1rem 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.4rem;
	font-weight: 700;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.main-nav-link:hover,
.dropdown-wrap:hover .main-nav-link {
	border-top-color: #fff200;
	box-shadow: inset 0 -6px 0 0 #fff200;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #fff200;
}

.main-nav-link.active-item,
.main-nav-link.selected,
.main-nav-link.w--current {
	box-shadow: inset 0 -6px 0 0 #fff200;
	color: #fff200;
	font-weight: 400;
}

#menu-item-specials .main-nav-link.active-item,
#menu-item-specials .main-nav-link.selected,
#menu-item-specials .main-nav-link.w--current {
	box-shadow: inset 0 -6px 0 0 transparent;
	color: #fff;
	font-weight: 700;
}

#menu-item-specials .main-nav-link:hover,
#menu-item-specials .dropdown-wrap:hover .main-nav-link {
	border-top-color: #fff200;
	box-shadow: inset 0 -6px 0 0 #fff200;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #fff200;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.rotator-section {
	position: relative;
	margin-top: 150px;
	background-image: var(--dark-wood-horizontal-bg);
}

.rotator {
	height: 65vh;
	padding-top: 0rem;
	padding-bottom: 0px;
	background-color: transparent;
	background-image: none;
}

.slide {
	border: 24px solid #fff;
}

.slideshow-arrow {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.slideshow-arrow:hover {
	opacity: 0.5;
}

.slideshow-arrow.left-arrow {
	display: block;
	background-image: none;
	opacity: 0.5;
	-webkit-transition-timing-function: ease-in-out;
	transition-timing-function: ease-in-out;
}

.slideshow-arrow.left-arrow:hover {
	opacity: 1;
}

.slideshow-arrow.right-arrow {
	display: block;
	background-image: none;
	opacity: 0.5;
	-webkit-transition-timing-function: ease-in-out;
	transition-timing-function: ease-in-out;
}

.slideshow-arrow.right-arrow:hover {
	opacity: 1;
}

.slidenav {
	left: 0px;
	right: 0px;
	bottom: -45px;
	display: block;
	height: 40px;
	padding-top: 0.75rem;
	font-size: 0.75rem;
}

.slide-caption-outer-wrap {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 20px;
	display: block;
	overflow: hidden;
	width: 535px;
	height: auto;
	margin-top: 40px;
	margin-right: auto;
	margin-left: auto;
	padding: 1rem;
	background-color: rgba(0, 0, 0, 0.8);
	text-align: center;
}

.slide-caption-header {
	margin-top: 0.75rem;
	margin-bottom: 1rem;
	font-family: 'Roboto Slab', sans-serif;
	color: #fff;
	font-size: 2.5rem;
	line-height: 3rem;
	font-weight: 400;
	text-shadow: 1px 1px 0 #000;
}

.slide-caption-paragraph h1,
.slide-caption-paragraph h2,
.slide-caption-paragraph h3,
.slide-caption-paragraph h4,
.slide-caption-paragraph h5,
.slide-caption-paragraph h6 {
	color: #fff;
	display: block;
}

.slide-caption-paragraph,
.slide-caption-inner-wrap p {
	margin-bottom: 0;
	font-family: 'Roboto Slab', sans-serif;
	color: #fff;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 400;
}

.slider-custom-link {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
}

.arrow-icon {
	display: block;
	margin-right: 1.5rem;
	margin-left: 1.5rem;
	color: #fff;
	font-size: 2rem;
}

.slide-caption-outer-wrap {
	opacity: 0;
	transform: scaleX(0.660000000000001) scaleY(0.660000000000001) scaleZ(1);
}

.slide.cycle-slide-active .slide-caption-outer-wrap {
	opacity: 1;
	transform: scaleX(1) scaleY(1) scaleZ(1);
	transition: opacity 300ms ease 0s, transform 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
	max-width: 100%;
}

.home-content-section {
	padding-top: 0rem;
	background-image: var(--dark-wood-horizontal-bg);
	background-position: 0px 0px;
	background-size: 256px;
}

.cb-static {
	padding: 1rem;
}

.cb-static p a {
	border-bottom: 1px dashed #ccc;
	color: #c9353c;
	font-weight: 400;
	text-decoration: none;
}

.cb-static.footer-cb-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	margin-right: auto;
	margin-left: auto;
	padding: 1.5rem 2rem 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: transparent;
	text-align: center;
}

.cb-static.cb-third {
	width: 33.33%;
	float: left;
}

.cb-static.cb-third.feature-cb {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 1rem 0rem 1rem 1rem;
	padding: 1.25rem 1.25rem 4.25rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	border: 2px solid #000;
	background-color: #fff;
}

.cb-static.cb-third.feature-cb.feature-cb-1 {
	margin-left: 0rem;
}

.cb-static.intro-cb {
	width: 100%;
	text-align: center;
}

.footer-section {
	background-color: #656565;
	display: block;
}

.signoff-wrapper {
	background-color: #333;
	display: block;
}

.footer-grip-link {
	width: 130px;
	height: 28px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 0;
	float: right;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	text-decoration: none;
	cursor: pointer;
}

.grip-logo-text-box {
	color: #fff;
	font-size: 0.5rem;
	line-height: 8px;
	text-decoration: none;
	text-transform: uppercase;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
}

#GRIPFooterLogoText {
	line-height: 8px;
}

#GRIPFooterLogoImg {
	margin-top: -15px !important;
}

.copyright-paragraph h1,
.copyright-paragraph {
	margin-top: 0;
	font-family: Roboto, sans-serif;
	margin-bottom: 1rem;
	line-height: 1.7rem;
	display: inline-block;
	color: #fff;
	font-size: 0.8rem;
}

.copyright-paragraph h1 {
	margin-bottom: 0;
}

.signoff-link {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	border-bottom: 1px solid #7a7a7a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 0.8rem;
	text-decoration: none;
	cursor: pointer;
}

.signoff-link:hover {
	border-bottom-color: transparent;
	color: #fff200;
	text-decoration: none;
}

.footer-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1300px;
	margin-right: auto;
	margin-bottom: 0rem;
	margin-left: auto;
	padding: 9px 2rem 12px;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.footer-list-item-link {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	padding: 0.5rem;
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	font-weight: 400;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

.footer-list-item-link:hover {
	color: #fff200;
	font-weight: 400;
}

.footer-list-item-link.textlink-middleout {
	color: #fff;
	cursor: pointer;
}

.footer-list-item-link.textlink-middleout:hover {
	color: #fff200;
	cursor: pointer;
}

.footer-list-item-link.textlink-middleout.w--current {
	color: #fff200;
	cursor: pointer;
}

.social-media-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.social-media-wrapper.footer-socmed-wrapper {
	float: right;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.social-media-link-block {
	width: 2.25rem;
	height: 2.25rem;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	background-image: url('../images/facebook_wht.png');
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

.social-media-link-block.footer-socmed-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #c2182d;
	background-image: none;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	cursor: pointer;
}

.social-media-link-block.footer-socmed-link-block:hover {
	background-color: #fa173d;
	background-image: none;
	-webkit-transform: scale(1.08);
	-ms-transform: scale(1.08);
	transform: scale(1.08);
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.horiz-divider {
	height: 2px;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	background-color: #000;
}

.header-search-field {
	display: inline-block;
	width: 70%;
	height: 2.5rem;
	margin-bottom: 0px;
	float: left;
	border: 0px none transparent;
}

.search-dd-button {
	display: none;
	width: 3rem;
	margin-right: 2px;
	margin-left: 2px;
	padding: 0.75rem 1rem;
	background-image: url('../images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 17px 17px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	border: 0px;
	background-color: transparent;
	cursor: pointer;
}

.header-search-form {
	display: block;
	height: 100px;
	border-style: none;
}

.header-search-form-wrapper {
	display: block;
	width: 50%;
	height: 100px;
	margin-bottom: 0px;
	padding: 1rem;
	float: right;
	background-color: rgba(0, 0, 0, 0.5);
}

.header-search-form-wrapper.search-top-wrapper {
	display: block;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-bottom: 2rem;
	padding-left: 2rem;
	float: none;
}

.search-session-message {
	height: 0px;
	margin-top: 0px;
	padding: 0px;
	opacity: 0;
}

.textlink-middleout {
	color: #df1c1c;
	text-decoration: none;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}

.success-message-box {
	border: 1px solid #1a5b0b;
	background-color: #f1fced;
	color: #1a5b0b;
}

.error-message-box {
	border: 1px solid #d50a2f;
	background-color: #fef3ee;
	color: #d50a2f;
}

.footer-heading {
	margin-top: 0rem;
	font-family: 'Roboto Slab', sans-serif;
	color: #fff;
	font-weight: 400;
}

.heading-link,
.left-bar-custom h1 a,
.main-content-wrapper h1 a,
.right-side-col h1 a,
.left-bar-custom h2 a,
.main-content-wrapper h2 a,
.right-side-col h2 a,
.left-bar-custom h3 a,
.main-content-wrapper h3 a,
.right-side-col h3 a,
.left-bar-custom h4 a,
.main-content-wrapper h4 a,
.right-side-col h4 a,
.left-bar-custom h5 a,
.main-content-wrapper h5 a,
.right-side-col h5 a,
.left-bar-custom h6 a,
.main-content-wrapper h6 a,
.right-side-col h6 a {
	border-bottom: 1px dashed #ccc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	color: #424242;
	scroll-margin-top: 160px;
}

.heading-link:hover,
.left-bar-custom h1 a:hover,
.main-content-wrapper h1 a:hover,
.right-side-col h1 a:hover,
.left-bar-custom h2 a:hover,
.main-content-wrapper h2 a:hover,
.right-side-col h2 a:hover,
.left-bar-custom h3 a:hover,
.main-content-wrapper h3 a:hover,
.right-side-col h3 a:hover,
.left-bar-custom h4 a:hover,
.main-content-wrapper h4 a:hover,
.right-side-col h4 a:hover,
.left-bar-custom h5 a:hover,
.main-content-wrapper h5 a:hover,
.right-side-col h5 a:hover,
.left-bar-custom h6 a:hover,
.main-content-wrapper h6 a:hover,
.right-side-col h6 a:hover {
	border-bottom-color: transparent;
	color: #c9353c;
	background-color: transparent;
}

.intro-paragraph,
.intro_paragraph {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	font-family: 'Roboto Condensed', sans-serif;
	color: #2e2e2e;
	font-size: 1.4rem;
	line-height: 2.1rem;
	display: block;
}

.header-flex-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100px;
	padding: 0.75rem 3% 1.25rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-image: -webkit-radial-gradient(circle farthest-corner at 50% 100%, hsla(0, 0%, 100%, 0.15), transparent 44%), -webkit-linear-gradient(270deg, transparent 85%, hsla(0, 0%, 100%, 0.07));
	background-image: radial-gradient(circle farthest-corner at 50% 100%, hsla(0, 0%, 100%, 0.15), transparent 44%), linear-gradient(180deg, transparent 85%, hsla(0, 0%, 100%, 0.07));
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.header-search-container {
	position: absolute;
	left: 2px;
	top: 100%;
	right: 2px;
	display: block;
	overflow: hidden;
	width: 100%;
	height: 100px;
	max-width: 1200px;
	min-height: 0px;
	margin-top: -1px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.close-link {
	margin-bottom: 0.75rem;
	padding: 0.25rem 0.25rem 0.25rem 0.5rem;
	float: right;
	text-decoration: none;
	text-indent: -99999px;
	background-color: #fff;
	color: #000;
}

.close-x-wrapper {
	width: 1rem;
	height: 1rem;
	margin-left: 0.25rem;
	float: left;
}

.close-text {
	float: left;
	color: #000;
	font-size: 0.95rem;
	line-height: 1rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
}

.close-image {
	display: block;
}

.search-container-push-down-rotator {
	overflow: hidden;
	height: 0vh;
}

.search-wrapper {
	display: block;
	height: 41px;
	margin-top: 10px;
	margin-right: 30px;
	float: right;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px none #fff;
	border-radius: 24px;
	display: flex;
	flex: 1;
}

.cb-header {
	margin-top: 0rem;
	margin-bottom: 0rem;
	font-family: 'Roboto Slab', sans-serif;
	font-weight: 400;
	text-align: center;
}

.cb-header.lower-cb-header {
	display: block;
	margin-top: 2rem;
	margin-bottom: 2rem;
	color: #fff;
	font-size: 2.5rem;
	line-height: 3rem;
	text-decoration: none;
	text-shadow: 1px 1px 0 #000;
}

.featured-item-price,
.content_box_price {
	margin-top: 0rem;
	margin-bottom: 0.5rem;
	font-family: Roboto, sans-serif;
	font-size: 1.25rem;
	font-weight: 700;
	text-align: center;
	display: block;
}

.featured-item-quantity,
.content_box_centered_text {
	font-weight: 400;
	text-align: center;
	display: block;
}

.slideshow-mask {
	margin-right: 5%;
	margin-left: 5%;
}

.cb-image-wrap {
	width: 100%;
	height: 200px;
	margin-bottom: 1rem;
	background-color: #5f5f5f;
}

.cb-image-wrap.cb-image-1 {
	background-image: url('../images/MS_Beef_tenderloin_steak_crop_3019C995A4923.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-image-wrap.cb-image-2 {
	margin-bottom: 1rem;
	background-image: url('../images/boneless-prime-rib.jpg');
	background-position: 50% 50%;
	background-size: cover;
}

.cb-image-wrap.cb-image-3 {
	background-image: url('../images/MS_family_favorites_bundle_no_text_798D6548B4040.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.intro-header {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 2.5rem;
	line-height: 3rem;
	font-weight: 400;
}

.intro-cb-subhead,
.intro-cb p {
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 2rem;
	line-height: 2.7rem;
	font-weight: 300;
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
	color: #424242;
}

.cb-static hr {
	border-color: #7a7a7a !important;
	border: 0px;
	color: #7a7a7a !important;
	margin-bottom: 0.75rem !important;
	margin-top: 0.75rem !important;
	border-top: 1px solid;
	width: 150px;
}

.cb-static p {
	margin-bottom: 0;
}

.cb-static p:last-of-type {
	margin-bottom: 1rem;
}

.slide-caption-inner-wrap {
	padding: 0.5rem 1rem 0.75rem;
	border: 1px solid #5f5f5f;
}

.divider-line {
	display: block;
	width: 100px;
	height: 1px;
	margin: 0.75rem auto;
	background-color: #7a7a7a;
}

.divider-line.feature-cb-divider-line {
	width: 150px;
}

.divider-line.long-divider-line {
	display: block;
	width: 500px;
}

.cb-paragraph,
.cb-static p {
	margin-right: 10px;
	margin-left: 10px;
}

.cb-link-block {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 400px;
	min-height: 300px;
	padding: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #000;
	background-image: none;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
}

.cb-link-block:hover {
	opacity: 0.7;
}

.cb-link-block.cb-link-block-1 {
	background-color: #000;
	background-image: url('../images/garnish_626311516.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-link-block.cb-link-block-1:hover {
	background-image: url('../images/garnish_626311516.jpg');
}

.cb-link-block.cb-link-block-2 {
	background-image: url('../images/cooking-cropped2_76750890.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-link-block.cb-link-block-3 {
	background-image: url('../images/OliveOil_80720343.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slide-image-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('../images/2000x1333_70qual_TS-472719534.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: inset 0 0 30px 5px rgba(0, 0, 0, 0.4);
}

.nav-menu-wrap {
	position: relative;
	width: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-left: 5.7rem;
	height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.nav-menu-wrap .menu-ul {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-evenly;
}

.nav-menu-wrap .menu-ul-1 {
	width: calc(50% - 5.7rem - 101px);
}

.nav-menu-wrap .menu-ul-2 {
	width: calc(50% - 101px);
}

.lower-cb-header-wrap {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	background-color: rgba(0, 0, 0, 0.8);
}

.cb-link-block-wrap {
	width: 50%;
	background-color: #000;
}

.cb-link-block-wrap.center-cb-link-block-wrap {
	display: block;
	border-right: 4px solid #fff;
	border-left: 4px solid #fff;
}

.button.shop-now-button {
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 10;
	width: 150px;
	height: 40px;
	min-width: 0px;
	margin-top: 0rem;
	margin-right: 0rem;
	padding: 0px 0rem;
	background-color: transparent;
	background-image: url('../images/arrow-medium-white.svg');
	background-position: 84% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	opacity: 0;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	-webkit-transition-timing-function: cubic-bezier(0.456, 0.124, 0.345, 1.188);
	transition-timing-function: cubic-bezier(0.456, 0.124, 0.345, 1.188);
	font-weight: 700;
	border-style: none;
	background-color: #c9353c;
}

.button.shop-now-button:hover {
	width: 165px;
	padding-right: 0rem;
	background-color: transparent;
	background-image: url('../images/arrow-medium-white.svg');
	background-position: 94% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	opacity: 1;
}

.button.shop-now-button:active {
	background-image: none;
}

.shop-now-button {
	position: relative;
	overflow: hidden;
	margin-top: 1rem;
	padding: 12px 2.5rem;
	background-color: #c9353c;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	text-decoration: none;
	cursor: pointer;
}

.shop-now-button:hover {
	padding-right: 3rem;
}

.cart-count {
	height: 24px;
	min-width: 24px;
	padding-top: 2px;
	padding-right: 5px;
	padding-left: 5px;
	border-radius: 14px;
	background-color: #fff200;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 400;
	text-align: center;
}

.cart-count:hover {
	background-color: #c9353c;
	color: #fff;
	text-align: center;
}

.desktop-search-button {
	width: 20px;
	height: 41px;
	padding: 1rem 1.3rem;
	float: right;
	border: 0 none;
	border-bottom: 1px none hsla(0, 0%, 100%, 0.55);
	background-color: transparent;
	background-image: url('../images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	opacity: 1;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	cursor: pointer;
}

.desktop-search-button:hover {
	background-image: url('../images/search_yellow.svg');
	background-size: 18px 18px;
}

.search-text {
	width: 120px;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 4px;
	font-family: 'Roboto Condensed', sans-serif;
	color: hsla(0, 0%, 100%, 0.55);
	font-size: 0.95rem;
	font-weight: 300;
}

.search-text:focus {
	position: relative;
	width: 130px;
}

.shop-now-text {
	padding-top: 11px;
	padding-bottom: 11px;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1rem;
	font-weight: 700;
}

.shop-button-wrapper {
	position: relative;
	display: inline-block;
	width: 150px;
	height: 40px;
	margin-top: 1rem;
	padding-right: 20px;
	padding-left: 20px;
	background-color: #c9353c;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	text-decoration: none;
}

.shop-button-wrapper:hover {
	width: 165px;
	padding-left: 10px;
}

.footer-nav {
	display: block;
	background-color: #292727;
}

.meat-market-functions {
	position: relative;
	top: 50%;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 41px;
	margin-right: 20px;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.cart-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 10px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
}

.cart-icon {
	width: 24px;
	height: 21px;
	margin-right: 10px;
	background-image: url('../images/cart-white.svg');
	background-position: 0px 0px;
	background-size: contain;
	background-repeat: no-repeat;
}

.cart-links {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.ecomm-link {
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-right: 10px;
	padding-left: 10px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.35rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.ecomm-link:hover {
	display: block;
	color: #fff200;
}

.ecomm-link.login-link {
	border-left: 1px solid #ccc;
}

.menu-ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.dropdown-wrap {
	overflow: hidden;
	display: inline-block;
}

.dropdown-list {
	position: absolute;
	z-index: 899;
	display: none;
	height: 0px;
	max-width: 400px;
	min-width: 200px;
	padding: 0.5px 0.5rem 0.5rem 0.25rem;
	background-color: #242424;
	box-shadow: 0 0 5px 0 #000;
	opacity: 0;
	text-align: left;
}

.open .dropdown-list {
	display: block;
	height: auto;
	opacity: 1;
	-webkit-transition: opacity 200ms ease 0s;
	transition: opacity 200ms ease 0s;
}

.dropdown-ul a,
.dropdown-link {
	position: relative;
	display: block;
	margin-top: 0.25rem;
	padding: 0.5rem;
	border-bottom: 1px solid #141313;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	font-weight: 400;
	letter-spacing: 0.05rem;
	text-decoration: none;
}

.dropdown-ul a:hover,
.dropdown-link:hover {
	position: relative;
	background-color: #000;
	color: #fff200;
}

.dropdown-ul a.w--current,
.dropdown-link.w--current {
	background-color: #000;
	color: #fff200;
	font-weight: 400;
}

.nav-drop-wrap-test {
	display: none;
	margin-right: 5px;
	margin-left: 5px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.dropdown-toggle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	font-weight: 300;
	text-transform: uppercase;
}

.dropdown-list {
	position: absolute;
	top: 49px;
	display: none;
	overflow: hidden;
	height: auto;
	max-width: none;
	min-width: 150px;
	opacity: 1;
}

.dropdown-ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.open .dropdown-list {
	z-index: 1;
}

.dropdown-ul a,
.dropdown-link {
	padding-left: 0.75rem;
	cursor: pointer;
}

input[type="text"].search-text {
	width: 100px;
	height: 41px;
	margin-bottom: 0px;
	padding: 6px 10px 4px ;
	float: left;
	border-style: none;
	border-bottom-width: 1px;
	border-bottom-color: #000;
	background-color: transparent;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	color: hsla(0, 0%, 100%, 0.55);
}

input[type="text"].search-text:focus {
	width: 130px;
	border-bottom-style: none;
	text-decoration: none;
}

.search-form {
	height: 41px;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.55);
}

.inside-page-wrapper {
	display: block;
	padding-top: 151px;
	background-image: var(--dark-wood-horizontal-bg);
	background-position: 0px 0px;
}

.inside-page-banner-section {
	position: relative;
	display: block;
	width: 100%;
	height: 500px;
	margin-right: auto;
	margin-left: auto;
	background-image: url('../images/inside-banner.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.styles-body {
	padding-right: 200px;
	padding-left: 200px;
}

.text-link,
.left-bar-custom a,
.main-content-wrapper a,
.right-side-col a {
	border-bottom: 1px dashed #ccc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #c9353c;
	font-weight: 400;
	text-decoration: none;
}

.text-link:hover,
.left-bar-custom a:hover,
.main-content-wrapper a:hover,
.right-side-col a:hover {
	border-bottom-style: dashed;
	border-bottom-color: transparent;
	background-color: #dedfe4;
}

.left-bar-custom a.primary,
.main-content-wrapper a.primary,
.right-side-col a.primary,
.left-bar-custom a.secondary,
.main-content-wrapper a.secondary,
.right-side-col a.secondary,
.left-bar-custom a.teriary,
.main-content-wrapper a.teriary,
.right-side-col a.teriary {
	border-bottom: 1px solid #c9353c;
	font-weight: 700;
	color: #424242;
}

.left-bar-custom a.primary,
.main-content-wrapper a.primary,
.right-side-col a.primary {
	color: #fff;
	font-weight: 700;
}

.left-bar-custom a.teriary,
.main-content-wrapper a.teriary,
.right-side-col a.teriary {
	font-weight: 400;
}

.main-content-wrapper a {
	word-wrap: break-word;
}

.text-link.breadcrumb-link {
	padding-right: 0rem;
	color: #424242;
	font-weight: 400;
}

.text-link.breadcrumb-link:hover {
	color: #c9353c;
	font-weight: 400;
}

.inside-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 3%;
	margin-left: 3%;
	padding: 1rem;
	background-color: #fff;
}

.inside-content-wrapper.page-title-container {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem 0%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: transparent;
}

.inside-content-wrapper.inside-cards-page-content-wrapper {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: #fff;
}

.right-col-item-wrap {
	padding-bottom: 1rem;
	border-top: 1px dashed #ccc;
}

.important-text {
	font-weight: 400;
	background-color: #231f20;
	color: #fff;
}

.h1-page-title {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	color: #fff;
	font-size: 3.5rem;
	line-height: 4.5rem;
	font-weight: 400;
	text-align: center;
	text-transform: none;
}

.page-title-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 3%;
	margin-left: 3%;
	padding-right: 10%;
	padding-left: 10%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-bottom: 4px solid #c9353c;
	background-color: rgba(0, 0, 0, 0.8);
}

.inside-page-cards-no-border.inside-cards-no-border-w-left-nav {
	width: 48%;
	height: 400px;
	margin-bottom: 1rem;
}

.inside-cards-link-block {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 400px;
	padding: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-image: url('../images/placeholder-image.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	border-bottom: 0 none !important;
}

.inside-cards-link-block:hover {
	opacity: 0.7;
}

.inside-cards-link-block.recipes-card-image {
	height: 100%;
	min-height: 0px;
	background-image: url('../images/garnish_626311516.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.inside-cards-link-block.video-card-image {
	height: 100%;
	background-image: url('../images/OliveOil_80720343.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.inside-card-header-wrap {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	background-color: rgba(0, 0, 0, 0.8);
}

.left-nav-section-title-wrap {
	position: absolute;
	top: 0px;
	right: -9px;
	overflow: hidden;
	width: 127%;
	background-image: url('../images/banner-inset-3.png');
	background-position: 100% 50%;
	background-size: 290px 50px;
	background-repeat: no-repeat;
	box-shadow: -5px 0 3px -2px #000;
}

.dashed-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 1.5rem 1.5rem 0.5rem;
	border: 1px dashed #424242;
	width: 100%;
}

.inside-cards-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-left: -1%;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
}

.nav-link-wrap {
	border-bottom: 1px dashed #ccc;
}

.nav-bar-outer-wrap {
	position: relative;
	padding-right: 3rem;
	padding-left: 3rem;
	background-color: #000;
	height: 50px;
}

.form-success-message {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #b5eebe;
	font-size: 1.125rem;
}

.form-error-message {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #f5d1d1;
	font-size: 1.125rem;
}

.card-save-link-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: 65px;
	padding-top: 2px;
	padding-right: 6px;
	padding-left: 6px;
	float: left;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 1px dashed hsla(0, 0%, 80%, 0.5);
	color: #fff;
	text-decoration: none;
	cursor: pointer;
}

.card-save-link-wrap:hover {
	color: #fa173d;
	text-decoration: none;
}

.recipe-card-tools-wrap {
	position: relative;
	left: 0px;
	right: 0px;
	bottom: 0px;
	height: 66px;
	border-top: 1px dashed hsla(0, 0%, 80%, 0.5);
}

.prep-time {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: 65px;
	padding-top: 2px;
	padding-right: 8px;
	padding-left: 8px;
	float: right;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
}

.prep-time-inside-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 4px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.card-info-text {
	display: inline-block;
	text-transform: uppercase;
}

.card-info-text.card-small-text {
	font-size: 0.71rem;
	line-height: 1rem;
	text-transform: uppercase;
}

.recipe-card-wrap {
	width: 31.5%;
	margin-bottom: 1rem;
}

.recipe-card-photo-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 190px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-image: url('../images/placeholder-image.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #efefef;
	text-decoration: none;
	text-indent: -99999px;
	border-bottom: 0 none !important;
}

.recipe-card-photo-link.recipe-card-image-1 {
	height: 190px;
	background-image: url('../images/BBQ-roasted-pork-tenderloin.jpg');
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.recipe-card-photo-link.recipe-card-image-1:hover {
	opacity: 0.7;
}

.recipe-card-photo-link.recipe-card-image-2 {
	background-image: url('../images/asian-beef-broccoli-noodle-bowlSM.jpg');
}

.recipe-card-photo-link.recipe-card-image-3 {
	background-image: url('../images/Bacon_Pork_CHops_with_BBQ_Glaze_535913EE03637.jpg');
}

.recipe-card-photo-link.recipe-card-image-4 {
	background-image: url('../images/beef-infusion.jpg');
	background-color: #fff !important;
	color: #000;
	text-indent: 0;
	font-size: 0px;
}

.recipe-card-title {
	overflow: hidden;
	max-height: 80px;
	margin: 0.75rem 10px 1rem;
	color: #fff;
	font-size: 1.1rem;
	line-height: 1.6rem;
	text-align: center;
}

.recipe-card-title a {
	color: #fff !important;
	border-bottom: 0 none !important;
}

.recipe-card-title a:hover {
	color: #fa173d !important;
	border-bottom: 0 none !important;
}

.clock {
	margin-right: 6px;
}

.hearthover {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 2;
	width: 20px;
	height: 20px;
	background-image: url('../images/heart-white.svg');
	background-position: 50% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
}

.hearthover:hover {
	background-image: url('../images/heart-red-outline.svg');
	background-size: 20px 20px;
}

.hearthover:active {
	background-image: none;
	background-size: auto;
}

.hearthover:focus {
	background-image: none;
}

.heart-save {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: none;
	width: 20px;
	height: 20px;
	background-image: url('../images/heart-red.svg');
	background-position: 50% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	opacity: 1;
}

.heart {
	display: inline-block;
	margin-right: 6px;
	height: 20px;
	width: 20px;
	background-image: url("/20160710165515/assets/images/heart-sprite.svg");
	background-repeat: no-repeat;
	background-size: 20px 60px;
	background-position: 50% 0%;
	vertical-align: sub;
}

.heart.grey {
	background-image: url("/20160710165515/assets/images/heart-sprite-grey.svg");
	background-position: 50% 50%;
	height: 15px;
	width: 15px;
	background-size: 15px 55px;
}

.heart-print {
	display: inline-block;
	margin-right: 6px;
	height: 15px;
	width: 15px;
	background-image: url("/20160710165515/assets/images/print-sprite.svg");
	background-repeat: no-repeat;
	background-size: 15px 30px;
	background-position: 50% 0%;
	vertical-align: sub;
	margin-top: -2px;
}

.save-to-recipe-box-link.saved::before {
	content: '';
	display: inline-block;
	margin-right: 6px;
	height: 20px;
	width: 20px;
	background-image: url("/20160710165515/assets/images/heart-sprite.svg");
	background-repeat: no-repeat;
	background-size: 20px 60px;
	background-position: 50% 100%;
	vertical-align: sub;
}

.card-save-link-wrap.saved,
.save-to-recipe-box-link.saved {
	cursor: auto;
}

.card-save-link-wrap.saved:hover {
	color: #ffffff;
	cursor: auto;
}

.save-to-recipe-box-link.saved:hover {
	color: #424242 !important;
	cursor: auto;
}

.card-save-link-wrap.saved .js-save-label::before {
	content: '';
	display: inline-block;
	margin-right: 6px;
	height: 20px;
	width: 20px;
	background-image: url("/20160710165515/assets/images/heart-sprite-white.svg");
	background-repeat: no-repeat;
	background-size: 20px 60px;
	background-position: 50% 100%;
	vertical-align: sub;
}

.card-save-link-wrap:hover .heart {
	background-position: 50% 50%;
}

.save-to-recipe-box-link:hover .heart.grey {
	background-image: url("/20160710165515/assets/images/heart-sprite.svg");
}

.save-to-recipe-box-link:hover .heart-print {
	background-position: 50% 100%;
}

.delete-heart {
	display: inline-block;
	margin-right: 6px;
	height: 20px;
	width: 20px;
	background-image: url("/20160710165515/assets/images/remove-sprite.svg");
	background-repeat: no-repeat;
	background-size: 20px 40px;
	background-position: 50% 0%;
	vertical-align: sub;
}

.card-save-link-wrap:hover .delete-heart {
	background-position: 50% 100%;
}

.recipe-card-summary-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 146px;
	max-height: 146px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: #231f20;
	background-image: none;
}

.recipes-tools {
	position: relative;
	z-index: 999;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1rem;
	padding-right: 3px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px dashed #ccc;
	border-bottom: 1px dashed #ccc;
}

.recipes-tools.buttons-only {
	margin-bottom: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.recipe-filter-form-block {
	display: inline-block;
	margin-right: 1rem;
	margin-bottom: 0px;
}

.add-ingredient-button {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 38px;
	background-image: url("/20160710165515/assets/images/add.svg");
	background-position: 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-color: transparent;
	border: 0;
	padding: 0;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	border-right: 1px solid #ccc;
}

.recipe-tools-label {
	display: inline-block;
	margin-right: 0.5rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	font-weight: 400;
}

.filter-dropdown-link {
	width: auto;
	height: 38px;
	min-width: 128px;
	padding: 5px 10px 5px 10px;
	border: 1px solid #ccc;
	text-decoration: none;
	background-repeat: no-repeat;
	background-position: 94% center;
	background-size: 15px 15px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.filter-dropdown-link::after {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.recipe-filter-default {
	display: inline-block;
	color: #c9353c;
	font-weight: 700;
}

.image {
	width: 15px;
	height: 15px;
	margin-top: 5px;
	margin-left: 10px;
	float: right;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.recipe-filter-dd-scroll-wrap {
	position: absolute;
	display: none;
	overflow: auto;
	width: 260px;
	height: 401px;
	margin-top: -1px;
	border: 1px solid #ccc;
	background-color: #fff;
}

.recipe-filter-primary-li-link,
.filter-dropdown-link optgroup {
	display: block;
	padding-left: 0.5rem;
	color: #c9353c;
	font-weight: 700;
	text-decoration: none;
}

.recipe-filter-primary-li-link:hover {
	background-color: #2e2e2e;
	color: #fff;
	display: block;
}

.filter-dropdown-link optgroup option {
	color: #424242;
	visibility: visible;
}

.recipe-filter-subitem-li-link {
	display: block;
	padding-left: 1.5rem;
	color: #424242;
	font-weight: 400;
	text-decoration: none;
}

.recipe-filter-subitem-li-link:hover {
	padding-left: 1.5rem;
	background-color: #2e2e2e;
	color: #fff;
}

.recipe-filter-subitem-list {
	margin-bottom: 0rem;
}

.recipe-filter-dd-wrapper {
	overflow: hidden;
}

.recipe-filter-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.recipe-filter-dd-all-default,
.recipe-filter-subitem-li[selected="selected"] {
	display: block;
	padding-left: 0.5rem;
	background-color: #2e2e2e;
	color: #fff;
	font-weight: 700;
	text-decoration: none;
}

.recipe-filter-dd-all-default:hover,
.recipe-filter-subitem-li:hover {
	display: block;
	background-color: #2e2e2e;
	color: #fff;
}

.recipe-search-button {
	width: 20px;
	height: 38px;
	padding: 1rem 1.3rem;
	float: right;
	background-image: url('../images/search_blk.svg');
	background-color: transparent;
	background-position: 50% 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	opacity: 1;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	border-top: 1px solid #cccccc !important;
	border-right: 1px solid #cccccc !important;
	border-bottom: 1px solid #cccccc !important;
}

.recipe-search.text-field.w-input {
	border-right: 0 none;
	width: 85%;
}

.recipe-search-button:hover {
	background-image: url('../images/search_red-C9353C.svg');
	background-size: 20px 20px;
}

.text-field {
	height: 38px;
	margin-bottom: 0px;
	border: 1px solid transparent;
}

.recipe-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	max-width: 200px;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.recipe-search-form.relative {
	position: relative;
}

.recipe-buttons {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	padding-top: 1rem;
	padding-bottom: 1rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.ingredients-wrapper {
	width: 31%;
	margin-right: 4%;
	padding-top: 0rem;
	float: left;
}

.recipe-content-wrapper {
	width: 65%;
	margin-top: 0rem;
	padding-top: 0rem;
	padding-bottom: 1rem;
	float: left;
}

.ingredient-li {
	padding-top: 8px;
	padding-bottom: 8px;
	border-bottom: 1px solid #ccc;
	font-size: 1rem;
	line-height: 1.3rem;
}

.ingredients-h2 {
	margin-top: 0rem;
	font-size: 1.5rem;
	line-height: 2.2rem;
}

.div-block-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 176px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #f0f0f0;
}

.recipe-content-wrapper .recipe-disclaimer p {
	font-weight: 100;
	font-size: 0.75rem;
	line-height: 1.1rem;
	font-style: italic;
	margin-top: 40px;
	color: #333333;
}

.recipe-content-wrapper .fb-comments,
.recipe-content-wrapper .fb-comments > span,
.recipe-content-wrapper .fb-comments > span > iframe {
	width: 100% !important;
}

.paragraph {
	margin-top: 0px;
	margin-right: 20px;
	margin-left: 20px;
	text-align: center;
}

.pagination-wrapper {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-top: 15px;
	padding-bottom: 15px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}

.pagination-form-block {
	display: inline-block;
	margin-bottom: 0px;
}

.pagination-form {
	display: inline-block;
}

.page-picker-wrap {
	display: inline-block;
	margin-right: 1.25rem;
}

.page-dropdown {
	display: inline-block;
	width: 48px;
	height: 25px;
	margin-right: 6px;
	margin-bottom: 0px;
	margin-left: 6px;
	padding: 0px 6px 1px 4px;
	background-image: -webkit-linear-gradient(270deg, #fff, #fff);
	background-image: linear-gradient(180deg, #fff, #fff);
	font-size: 0.9rem;
	line-height: 1rem;
}

.pagination-label {
	display: inline-block;
	font-size: 1rem;
	font-weight: 400;
}

.previous {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #424242;
	font-size: 0.9rem;
	font-weight: 700;
	text-decoration: none;
}

.previous:hover {
	-webkit-transform: translate(-1px, 0px);
	-ms-transform: translate(-1px, 0px);
	transform: translate(-1px, 0px);
	color: #c9353c;
}

.next {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #424242;
	font-size: 0.9rem;
	font-weight: 700;
	text-decoration: none;
}

.next:hover {
	-webkit-transform: translate(1px, 0px);
	-ms-transform: translate(1px, 0px);
	transform: translate(1px, 0px);
	color: #c9353c;
}

.recipe-stats {
	display: inline-block;
	margin-right: 0rem;
	margin-bottom: 0rem;
	font-size: 0.82rem;
	line-height: 0.9rem;
	font-weight: 700;
}

.stats-outer-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.save-to-recipe-box-link {
	display: block;
	padding: 2px 6px;
	float: left;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #424242 !important;
	font-size: 0.85rem;
	line-height: 1rem;
	text-decoration: none;
	border: 0 none !important;
	cursor: pointer;
}

.save-to-recipe-box-link:hover {
	display: block;
	color: #c9353c !important;
	background-color: transparent !important;
}

.recipe-forms-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1rem;
	padding-bottom: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.my-shopping-list-functions {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	justify-content: space-between;
}

a.print-list,
.clear-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.print-list::before {
	height: 20px;
	width: 20px;
	margin-right: 6px;
	margin-top: -2px;
	background-image: url("/20160710165515/assets/images/print-sprite.svg");
	background-size: 20px 40px;
	content: '';
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 50% 0;
}

.print-list {
	padding: 2px 4px 0;
}

.clear-list::after {
	height: 15px;
	width: 15px;
	margin-left: 6px;
	margin-top: -1px;
	background-image: url("/20160710165515/assets/images/clear-list-sprite.svg");
	background-size: 15px 30px;
	content: '';
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 50% 0;
}

.js-clear-all-ingredients {
	border-bottom: 1px dashed #ccc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #c9353c;
	font-weight: 400;
	cursor: pointer;
	padding: 2px 4px 0;
}

.js-clear-all-ingredients:hover {
	border-bottom-style: dashed;
	border-bottom-color: transparent;
	background-color: #dedfe4;
}

.my-shopping-list tr td:last-child {
	width: 30px;
	background-image: url("/20160710165515/assets/images/remove-grey.svg");
	background-size: 15px;
	background-position: 50%;
	background-repeat: no-repeat;
	cursor: pointer;
}

.my-shopping-list tr td:last-child:hover {
	background-image: url("/20160710165515/assets/images/remove-red.svg");
}

.shoppingListForm.recipe-forms-wrap {
	-webkit-box-align: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
}

.shoppingListForm .recipe-tools-label {
	white-space: nowrap;
}

.shoppingListForm .primary {
	margin-top: 9px;
	white-space: nowrap;
}

.shoppingListForm .recipe-filter-form-block {
	margin-right: 0.5rem;
}

.shoppingListForm .last-recipe-filter-form-block {
	margin-right: 0;
}

@media (max-width: 991px) {
	.shoppingListForm.recipe-forms-wrap {
		display: block;
	}

	.shoppingListForm .last-recipe-filter-form-block {
		display: inline-block;
		vertical-align: top;
	}
}

@media (max-width: 540px) {
	.shoppingListForm .recipe-filter-form-block {
		display: block;
		text-align: center;
	}

	.shoppingListForm .last-recipe-filter-form-block {
		margin-top: 15px;
	}

	.shoppingListForm .recipe-search-form.relative {
		margin: 0 auto;
	}
}

@media (max-width: 479px) {
	.shoppingListForm .last-recipe-filter-form-block {
		margin-bottom: 15px;
	}

	.shoppingListForm .recipe-buttons {
		height: auto;
	}
}

.save-recipe {
	display: inline-block;
	line-height: 1rem;
	text-transform: uppercase;
}

.recipe-stats-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 25%;
	padding: 0.5rem 0.25rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-right: 1px dashed #ccc;
	text-align: center;
}

.recipe-stats-inner-wrap.print {
	width: 15%;
	border-right-style: none;
}

.recipe-stats-inner-wrap.save-recipe-box {
	width: 30%;
}

.recipe-stats-inner-wrap.servings {
	width: 15%;
}

.recipe-stats-inner-wrap.total-time {
	width: 15%;
}

.recipe-stats-label {
	display: inline-block;
	margin-right: 0rem;
	margin-bottom: 0.25rem;
	font-size: 0.82rem;
	line-height: 0.9rem;
	text-transform: uppercase;
}

.stats-save-print-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px none #ccc;
	border-bottom: 1px dashed #ccc;
}

.div-block-4 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

html.w-mod-js *[data-ix="shop-arrow"] {
	opacity: 0;
	-webkit-transform: translate(-30px, 0px);
	-ms-transform: translate(-30px, 0px);
	transform: translate(-30px, 0px);
}

html.w-mod-js *[data-ix="slide-caption-load"] {
	opacity: 0;
	-webkit-transform: scale(0.660000000000001, 0.660000000000001);
	-ms-transform: scale(0.660000000000001, 0.660000000000001);
	transform: scale(0.660000000000001, 0.660000000000001);
}

@media screen and (max-width: 1240px) {
	.card-info-text {
		font-size: 0.9rem;
	}
}

@media screen and (max-width: 1170px) {
	.recipe-card-wrap {
		width: 47%;
	}
}

.social-icons {
	margin-right: 1rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.social-link {
	width: 30px;
	height: auto;
	margin-right: 0.5rem;
}

.social-icons.mobile {
	display: none;
}

@media (max-width: 991px) {
	h1 {
		margin-right: 0rem;
		font-size: 2.25rem;
		line-height: 3rem;
	}

	h3 {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	h4 {
		font-size: 1.4rem;
		line-height: 2.1rem;
	}

	.button.search-button.search-top-button {
		width: 25%;
		border-radius: 0px 3px 3px 0px;
	}

	.button.cb-flex-button {
		display: block;
		width: 300px;
		margin-right: auto;
		margin-left: auto;
	}

	.button.cb-flex-button:hover {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.main-content-wrapper,
	.main-content-wrapper.right-sidebar {
		width: 100%;
		margin-top: 0rem;
		padding-top: 0.5rem;
		padding-bottom: 0.75rem;
	}

	.container {
		max-width: 991px;
	}

	.container.inside-page-content-container {
		position: relative;
		max-width: 991px;
		min-height: 0px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.inside-page-content-container.page-title-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.container.footer-container {
		max-width: 991px;
	}

	.container.signoff-container {
		max-width: 991px;
	}

	.container.homepage-flex-content-container {
		max-width: 991px;
		padding-right: 30px;
		padding-left: 30px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.homepage-flex-content-container.lower-row-container {
		max-width: 991px;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.left-nav-col {
		display: none;
	}

	.inside-content-column {
		width: 100%;
		margin-top: 0rem;
		margin-left: 0rem;
	}

	.inside-content-column.no-sidebars-content-column {
		margin-right: 0%;
		margin-left: 0%;
	}

	.breadcrumbs-wrapper {
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.breadcrumb-current {
		margin-top: 0.25rem;
	}

	.content-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.right-side-col {
		width: 100%;
		margin-top: 0rem;
		margin-left: 0rem;
		padding-bottom: 0rem;
	}

	.header-section {
		position: relative;
	}

	.brand {
		display: none;
	}

	.brand.mobile-brand {
		position: absolute;
		left: 0px;
		top: 7px;
		right: 0px;
		bottom: 38px;
		display: block;
		width: 146px;
		height: 170px;
		min-width: 0px;
		margin-top: 0px;
		margin-right: auto;
		margin-left: auto;
	}

	.nav-menu {
		width: 90%;
		padding-top: 0.5rem;
		background-color: #242424;
	}

	.nav-menu.nav-left {
		display: none;
	}

	.nav-menu.mobile-nav-menu {
		z-index: 10000;
	}

	.main-nav-link {
		width: 100%;
		padding-bottom: 0.75rem;
		border-bottom: 1px solid #141313;
		text-align: left;
	}

	.main-nav-link:hover {
		box-shadow: none;
	}

	.main-nav-link.active-item,
	.main-nav-link.w--current {
		box-shadow: inset 0 -6px 0 0 #fff200;
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 100%;
		margin-right: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: solid;
		-webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}

	.main-nav-link.mobile-duplicate:hover {
		box-shadow: none;
	}

	.main-nav-link.mobile-duplicate.w--current {
		box-shadow: inset 0 0 0 0 transparent;
		font-weight: 700;
	}

	.main-nav-link.mobile-duplicate.w--current:hover {
		box-shadow: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.main-nav-link.two-part-mobile-nav-link-duplicate {
		width: 88%;
		border-bottom-style: none;
	}

	.rotator-section {
		position: static;
		top: 139px;
		margin-top: 0px;
	}

	.rotator {
		height: 60vh;
	}

	.slideshow-arrow.left-arrow {
		width: 50px;
	}

	.slideshow-arrow.right-arrow {
		width: 50px;
	}

	.slide-caption-header {
		font-size: 2.25rem;
		line-height: 2.75rem;
	}

	.arrow-icon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 100%;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transition: opacity 200ms ease;
		transition: opacity 200ms ease;
		font-size: 1.5rem;
	}

	.arrow-icon:hover {
		font-size: 1.5rem;
		color: #fff200;
	}

	.slide {
		border-style: none;
	}

	.cb-static {
		width: 100%;
	}

	.cb-static.cb-third.feature-cb {
		width: 100%;
		margin-left: 0rem;
	}

	.footer-grip-link {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.menu-button {
		display: block;
		height: 60px;
		margin-right: 0px;
		padding: 0.75rem 1.13rem;
		float: right;
		clear: left;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
		color: #fff;
		text-align: center;
	}

	.menu-button:hover {
		display: block;
		color: #fff200;
	}

	.menu-button.w--open {
		display: block;
		background-color: transparent;
		color: #fff200;
	}

	.menu-icon {
		font-size: 2rem;
		line-height: 2rem;
	}

	.menu-text {
		font-size: 0.75rem;
		line-height: 0.75rem;
	}

	.mobile-link-label {
		display: inline-block;
		margin-bottom: 0rem;
	}

	.mobile-menu-arrow {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 12%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-left: 1px solid #141313;
		-webkit-transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
		transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	}

	.footer-nav-list {
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.footer-list-item-link.textlink-middleout {
		margin-right: 0.25rem;
		margin-left: 0.25rem;
		font-size: 0.95rem;
	}

	.social-media-wrapper.footer-socmed-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid #141313;
	}

	.header-search-field {
		width: 75%;
		border-radius: 3px 0px 0px 3px;
	}

	.search-dd-button {
		display: none;
	}

	.search-dd-button.mobile-search-dd-button {
		display: block;
		width: 60px;
		height: 70px;
		margin-right: 10px;
		padding: 1rem 1.3rem;
		float: right;
		clear: right;
		background-image: url('../images/search_wht.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
		text-indent: -99999px;
	}

	.header-search-form.search-top-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 80%;
		height: auto;
		margin-right: 2rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.header-search-form-wrapper.search-top-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid #5f5f5f;
	}

	.intro-paragraph,
	.intro_paragraph {
		margin-top: 0rem;
		font-size: 1.3rem;
		line-height: 2rem;
	}

	.header-flex-container {
		display: block;
		height: 140px;
		padding-top: 0.5rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.close-link {
		width: 24px;
		height: 24px;
		margin-bottom: 0rem;
		border-radius: 14px;
		background-color: #fff;
		background-image: url('../images/close_x_black.svg');
		background-position: 50% 50%;
		background-size: 20px 20px;
		background-repeat: no-repeat;
		color: #000;
	}

	.cb-header.lower-cb-header {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.slideshow-mask {
		margin-right: 0px;
		margin-left: 0px;
	}

	.cb-link-block {
		height: 300px;
	}

	.cb-link-block-wrap {
		width: 50%;
	}

	.button.shop-now-button {
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
	}

	.button.shop-now-button:hover {
		width: 150px;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
	}

	.shop-button-wrapper:hover {
		width: 150px;
		padding-left: 20px;
		background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	}

	.meat-market-functions {
		top: 50%;
		right: auto;
		bottom: 0px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		float: left;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.mobile-menu-search-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 20px;
		float: right;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.search-container-top {
		overflow: hidden;
		height: 0px;
		background-color: #000;
	}

	.search-close-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		padding: 0.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.inside-page-wrapper {
		padding-top: 0px;
	}

	.inside-page-banner-section {
		height: 347px;
	}

	.styles-body {
		padding-right: 40px;
		padding-left: 40px;
	}

	.inside-content-wrapper {
		margin-right: 2%;
		margin-left: 2%;
	}

	.inside-content-wrapper.page-title-container {
		max-width: 991px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.right-col-item-wrap {
		padding-bottom: 0.75rem;
	}

	.h1-page-title {
		font-size: 2.5rem;
		line-height: 3.5rem;
	}

	.page-title-inner-wrap {
		margin-right: 2%;
		margin-left: 2%;
		padding-right: 3%;
		padding-left: 3%;
	}

	.inside-page-cards-no-border.inside-cards-no-border-w-left-nav {
		height: 300px;
	}

	.inside-cards-link-block {
		height: 100%;
	}

	.dashed-wrap {
		padding-top: 0.75rem;
		padding-bottom: 0.5rem;
	}

	.card-info-text {
		font-size: 0.9rem;
	}

	.card-info-text.card-small-text {
		font-size: 0.68rem;
	}

	.recipe-card-wrap {
		width: 47%;
	}

	.recipe-card-title {
		font-size: 1.05rem;
		line-height: 1.55rem;
	}

	.recipes-tools {
		height: auto;
		margin-bottom: 1rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-top: 1px dashed #ccc;
		border-bottom: 1px dashed #ccc;
	}

	.recipes-tools.buttons-only {
		margin-bottom: 0rem;
		padding-top: 0rem;
	}

	.filter-dropdown-link {
		height: 38px;
	}

	.recipe-buttons {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 1rem;
		margin-bottom: 1rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.ingredients-wrapper {
		width: 100%;
		margin-bottom: 1rem;
	}

	.recipe-content-wrapper {
		width: 100%;
		margin-top: 0rem;
		padding-top: 0.5rem;
		padding-bottom: 0.75rem;
	}

	.save-to-recipe-box-link {
		padding-top: 0.6rem;
		padding-bottom: 0.6rem;
	}

	.save-recipe {
		margin-bottom: 0rem;
		font-size: 0.9rem;
	}

	.recipe-stats-inner-wrap.print {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.recipe-stats-inner-wrap.save-recipe-box {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.stats-save-print-wrap {
		border-top-style: none;
	}

	.social-icons.mobile {
		display: block;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 2rem;
		line-height: 2.75rem;
	}

	h2 {
		font-size: 1.75rem;
		line-height: 2.45rem;
	}

	h3 {
		font-size: 1.5rem;
		line-height: 2.2rem;
	}

	h4 {
		font-size: 1.35rem;
		line-height: 1.95rem;
	}

	ul {
		margin-top: 0.5rem;
		margin-bottom: 0.75rem;
		padding-left: 1.25rem;
	}

	ol {
		padding-left: 20px;
	}

	blockquote {
		font-size: 1.05rem;
		line-height: 1.75rem;
	}

	.main-content-wrapper {
		padding-top: 0.5rem;
		padding-bottom: 0.75rem;
	}

	.container {
		max-width: 767px;
	}

	.container.inside-page-content-container {
		max-width: 767px;
	}

	.container.footer-container {
		max-width: 767px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-top-width: 2px;
	}

	.container.signoff-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}

	.container.homepage-flex-content-container {
		max-width: 767px;
	}

	.container.homepage-flex-content-container.callout-container {
		max-width: 767px;
	}

	.container.homepage-flex-content-container.lower-row-container {
		max-width: 767px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.breadcrumbs-wrapper {
		font-size: 0.85rem;
	}

	.header-section {
		position: relative;
	}

	.brand.mobile-brand {
		top: 5px;
		width: 120px;
		height: 130px;
	}

	.brand.mobile-brand.w--current {
		height: 137px;
	}

	.rotator-section {
		top: 99px;
	}

	.rotator {
		height: 475px;
	}

	.slide-caption-outer-wrap {
		bottom: 0px;
	}

	.slide-caption-header {
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.slide-caption-paragraph,
	.slide-caption-inner-wrap p {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	.cb-static {
		width: 100%;
	}

	.cb-static.footer-cb-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-grip-link {
		margin-top: 2rem;
	}

	.copyright-paragraph {
		display: block;
	}

	.menu-button {
		height: 60px;
		margin-top: 0px;
		margin-right: 0px;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.footer-nav-list {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 5%;
		padding-left: 5%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-nav-list-item {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.copyright-privacy-sitemap-wrapper {
		display: block;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.search-dd-button.mobile-search-dd-button {
		width: 45px;
		height: 60px;
		margin-right: 0px;
		padding: 0.75rem 1rem;
	}

	.header-search-form-wrapper.search-top-wrapper {
		height: auto;
		padding-right: 1rem;
	}

	.intro-paragraph,
	.intro_paragraph {
		font-size: 1.25rem;
		line-height: 1.95rem;
	}

	.header-flex-container {
		height: 100px;
		padding-top: 1rem;
		padding-right: 0.5rem;
		padding-left: 1rem;
	}

	.cb-header.lower-cb-header {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
	}

	.intro-header {
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.intro-cb-subhead,
	.intro-cb p {
		font-size: 1.75rem;
		line-height: 2.15rem;
	}

	.slide-caption-inner-wrap {
		padding-bottom: 0.65rem;
	}

	.divider-line.long-divider-line {
		width: 350px;
	}

	.cb-link-block.cb-link-block-1 {
		border-bottom: 2px solid #fff;
	}

	.cb-link-block-wrap {
		width: 100%;
	}

	.cb-link-block-wrap.center-cb-link-block-wrap {
		border-bottom: 2px solid #fff;
		border-right-style: none;
		border-left-style: none;
	}

	.meat-market-functions {
		left: 0px;
		top: 0%;
		right: 0px;
		bottom: auto;
		width: 25%;
		height: auto;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.cart-link {
		margin-bottom: 20px;
		margin-left: 10px;
	}

	.cart-links {
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		min-width: 128px;
	}

	.ecomm-link {
		margin-top: 0px;
	}

	.ecomm-link.login-link {
		margin-bottom: 10px;
	}

	.mobile-menu-search-wrap {
		margin-top: 0px;
	}

	.inside-page-banner-section {
		display: block;
		height: 347px;
	}

	.inside-content-wrapper {
		margin-right: 1%;
		margin-left: 1%;
	}

	.inside-content-wrapper.page-title-container {
		max-width: 767px;
	}

	.h1-page-title {
		font-size: 2.25rem;
		line-height: 3rem;
	}

	.page-title-inner-wrap {
		margin-right: 1%;
		margin-left: 1%;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.inside-page-cards-no-border.inside-cards-no-border-w-left-nav {
		width: 100%;
	}

	.inside-page-cards-no-border.inside-cards-no-border-w-left-nav.empty {
		display: none;
	}

	.dashed-wrap {
		padding-bottom: 0.25rem;
	}

	.recipe-card-wrap {
		width: 100%;
	}

	.recipes-tools {
		padding-top: 0.75rem;
		padding-bottom: 0.25rem;
	}

	.recipes-tools.buttons-only {
		margin-bottom: 0rem;
		border-bottom-style: none;
	}

	.recipe-filter-form-block.last-recipe-filter-form-block {
		margin-right: 0rem;
	}

	.filter-dropdown-link {
		width: auto;
		height: 38px;
		min-width: 140px;
	}

	.recipe-filter-form {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.recipe-search-form {
		width: auto;
		min-width: 140px;
	}

	.ingredients-wrapper {
		margin-bottom: 1rem;
	}

	.recipe-content-wrapper {
		padding-top: 0.5rem;
		padding-bottom: 0.75rem;
	}

	.stats-outer-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.save-to-recipe-box-link {
		padding-top: 0.7rem;
		padding-bottom: 0.65rem;
	}

	.recipe-forms-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0rem;
		padding-bottom: 0.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.recipe-stats-inner-wrap {
		width: 33.33%;
		margin-bottom: 0.25rem;
		border-bottom: 1px none #ccc;
	}

	.recipe-stats-inner-wrap.print {
		width: 50%;
		margin-bottom: 0rem;
		border-top: 1px dashed #ccc;
		border-bottom-style: none;
	}

	.recipe-stats-inner-wrap.save-recipe-box {
		width: 50%;
		margin-bottom: 0rem;
		border-top: 1px dashed #ccc;
		border-bottom-style: none;
	}

	.recipe-stats-inner-wrap.servings {
		width: 33.33%;
		margin-bottom: 3px;
		border-bottom-style: none;
	}

	.recipe-stats-inner-wrap.total-time {
		width: 33.33%;
		border-right-style: none;
	}

	.stats-save-print-wrap {
		padding-bottom: 0rem;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		border-top: 1px dashed #ccc;
	}

	.social-icons {
		margin-right: 0rem;
	}

	.social-link {
		width: 25px;
	}

	.social-icons.mobile .social-link:last-child {
		margin-right: 0rem;
	}
}


@media (max-width: 479px) {
	.social-icons {
		position: absolute;
		top: -32.375px;
		right: 8px;
	}

	.slideshow-arrow.right-arrow,
	.slideshow-arrow.left-arrow {
		display: none;
	}

	.slidenav {
		bottom: -30px;
	}

	h1 {
		font-size: 1.75rem;
		line-height: 2.4rem;
	}

	h2 {
		font-size: 1.55rem;
		line-height: 2.15rem;
	}

	h3 {
		font-size: 1.4rem;
		line-height: 2.05rem;
	}

	h4 {
		font-size: 1.3rem;
		line-height: 1.9rem;
	}

	h5 {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}

	blockquote {
		line-height: 1.75rem;
	}

	.button.search-button {
		width: 30%;
		min-width: 0px;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		font-size: 0.9rem;
	}

	.button.search-button.search-top-button {
		width: 30%;
		padding-right: 0.25rem;
		padding-left: 0.25rem;
	}

	.button.cb-flex-button {
		width: 90%;
		max-width: 300px;
	}

	.button.recipe-button {
		width: 100%;
		max-width: 250px;
		min-width: 0px;
		margin-bottom: 1rem;
		margin-left: 0rem;
	}

	.main-content-wrapper {
		padding-bottom: 0.75rem;
	}

	.container {
		max-width: 479px;
	}

	.container.inside-page-content-container {
		max-width: none;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.container.footer-container {
		max-width: 479px;
	}

	.container.homepage-flex-content-container {
		max-width: 479px;
		padding-top: 1rem;
		padding-right: 15px;
		padding-left: 15px;
	}

	.container.homepage-flex-content-container.callout-container {
		max-width: 479px;
	}

	.container.homepage-flex-content-container.lower-row-container {
		max-width: 479px;
	}

	.navbar {
		height: 149px;
	}

	.brand {
		width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.brand.mobile-brand {
		width: 73px;
		height: 110px;
	}

	.brand.mobile-brand.w--current {
		height: 100px;
	}

	.nav-menu {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.main-nav-link {
		padding-left: 0.5rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 83%;
	}

	.main-nav-link.mobile-duplicate.w--current {
		width: 100%;
	}

	.rotator {
		height: auto;
	}

	.slide-caption-outer-wrap {
		position: static;
		overflow: visible;
		width: 100%;
		margin-top: 0px;
	}

	.cb-static {
		width: 100%;
	}

	.cb-static.footer-cb-3 {
		padding-right: 1rem;
		padding-bottom: 0rem;
		padding-left: 1rem;
	}

	.cb-static.cb-third.feature-cb {
		padding-bottom: 5rem;
	}

	.menu-button {
		width: 40px;
		height: 40px;
		padding: 5px 0rem 0rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		line-height: 1.6rem;
	}

	.menu-icon {
		text-align: center;
	}

	.menu-text {
		display: none;
	}

	.mobile-menu-arrow {
		width: 17%;
	}

	.footer-nav-list {
		display: block;
		-webkit-column-count: 2;
		column-count: 2;
	}

	.footer-nav-list-item {
		margin-top: 0rem;
	}

	.footer-list-item-link.textlink-middleout {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.footer-list-item-link.textlink-middleout.w--current {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.social-media-link-block.footer-socmed-link-block {
		width: 2rem;
		height: 2rem;
		margin-bottom: 0rem;
	}

	.header-search-field {
		width: 65%;
	}

	.search-dd-button.mobile-search-dd-button {
		width: 40px;
		height: 40px;
		margin-right: 5px;
	}

	.header-search-form.search-top-form {
		height: auto;
		margin-right: 0rem;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.header-search-form-wrapper {
		min-width: 100%;
	}

	.header-search-form-wrapper.search-top-wrapper {
		height: auto;
		padding: 0.5rem 0.5rem 0.5rem 1rem;
	}

	.intro-paragraph,
	.intro_paragraph {
		font-size: 1.25rem;
		line-height: 1.9rem;
	}

	.header-flex-container {
		position: absolute;
		left: 0px;
		right: 0px;
		bottom: 0px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 40px;
		padding: 0rem;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-content: flex-end;
		-ms-flex-line-pack: end;
		align-content: flex-end;
		border-top: 1px solid #5f5f5f;
		background-color: #000;
	}

	.cb-header.lower-cb-header {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	.divider-line.long-divider-line {
		width: 200px;
	}

	.slide-image-wrap {
		display: block;
		width: 100%;
		height: 200px;
	}

	.cart-count {
		height: 20px;
		min-width: 20px;
		line-height: 1rem;
	}

	.meat-market-functions {
		width: auto;
		min-width: 200px;
		height: 40px;
		margin-right: 0px;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.cart-link {
		margin-bottom: 10px;
		min-width: 50px;
	}

	.cart-icon {
		width: 20px;
		height: 20px;
	}

	.ecomm-link {
		font-size: 0.85rem;
	}

	.mobile-menu-search-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 40px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.search-container-top {
		overflow: hidden;
	}

	.search-close-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 60px;
	}

	.styles-body {
		padding-right: 20px;
		padding-left: 20px;
	}

	.inside-content-wrapper {
		margin-right: 0%;
		margin-left: 0%;
	}

	.inside-content-wrapper.page-title-container {
		padding-right: 0%;
		padding-left: 0%;
	}

	.h1-page-title {
		font-size: 1.95rem;
		line-height: 2.75rem;
		letter-spacing: 0rem;
		text-transform: none;
	}

	.page-title-inner-wrap {
		margin-right: 0%;
		margin-left: 0%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.inside-page-cards-no-border.inside-cards-no-border-w-left-nav {
		height: 250px;
	}

	.dashed-wrap {
		padding-right: 1rem;
		padding-bottom: 0rem;
		padding-left: 1rem;
	}

	.recipe-card-wrap {
		width: 100%;
	}

	.recipes-tools {
		width: 100%;
		padding-top: 0.5rem;
		padding-right: 0px;
	}

	.recipes-tools.buttons-only {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.recipe-filter-form-block {
		width: 100%;
	}

	.recipe-filter-form-block.last-recipe-filter-form-block {
		width: 100%;
	}

	.filter-dropdown-link {
		width: 100%;
		max-width: none;
		min-width: 0px;
	}

	.recipe-filter-dd-wrapper {
		width: 100%;
	}

	.recipe-filter-form {
		margin-bottom: 0.5rem;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.recipe-search-form {
		max-width: none;
		min-width: 0px;
	}

	.recipe-buttons {
		width: 100%;
		height: 100px;
		margin-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}

	.recipe-content-wrapper {
		padding-bottom: 0.75rem;
	}

	.stats-outer-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.recipe-forms-wrap {
		display: block;
		max-width: 250px;
	}

	.recipe-stats-inner-wrap {
		width: 37%;
	}

	.recipe-stats-inner-wrap.print {
		width: 50%;
	}

	.recipe-stats-inner-wrap.save-recipe-box {
		width: 50%;
	}

	.recipe-stats-inner-wrap.servings {
		width: 30%;
	}

	.recipe-stats-inner-wrap.total-time {
		width: 33%;
	}
}

/* FONT SMOOTHING */
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

/* CONTROL PHONE NUMBER COLOR */
a[href^=tel] {
	color: #000000;
	visibility: visible;
}

/* CONTROL CURSOR CLICK & DRAG HIGHLIGHT COLOR */
/* Mozilla-based browsers */
::-moz-selection {
	background-color: #000000;
	color: #ffffff;
	visibility: visible;
}

/* Safari browser */
::selection {
	background-color: #000000;
	color: #ffffff;
	visibility: visible;
}

/* TEXT LINK UNDERLINE ANIMATION - Middle Out */
.textlink-middleout {
	display: inline-block;
	position: relative;
	padding-bottom: 1px;
}

.textlink-middleout:after {
	content: '';
	display: block;
	margin: auto;
	height: 1px;
	width: 0px;
	background: transparent;
	transition: width 0.3s ease, background-color 0.3s ease;
}

.textlink-middleout:hover:after {
	width: 100%;
	background: gray;
}

/* ADJUSTING HEADER ON NARROWER DESKTOP VIEWS */
@media screen and (max-width: 1236px) {
	.main-nav-link {
		margin-left: 2px;
		margin-right: 2px;
		padding-left: 12px;
		padding-right: 12px;
		font-size: 1rem;
	}

	.nav-menu.nav-right {
		padding-left: 5px;
		padding-right: 5px;
	}

	.brand {
		width: 146px;
		min-width: 0px;
		margin-left: 5px;
		margin-right: 5px;
	}

	.nav-menu-wrap .menu-ul {
		-webkit-box-pack: space-evenly;
		-ms-flex-pack: space-evenly;
		justify-content: space-evenly;
	}

	.nav-menu-wrap .menu-ul-1 {
		width: calc(50% - 78px);
	}

	.nav-menu-wrap .menu-ul-2 {
		width: calc(50% + 5.1rem - 78px);
	}

	.nav-menu-wrap {
		padding-left: 5.1rem;
	}

	.nav-menu.nav-left {
		padding-left: 5px;
		padding-right: 5px;
	}

	.search-wrapper {
		margin-right: 15px;
	}

	.search-text:focus {
		width: 120px;
	}

	.meat-market-functions {
		margin-right: 0px;
	}

	.cart-link {
		margin-right: 5px;
	}
}

/* HIDING DESKTOP MAIN NAV ELEMENTS ON MOBILE
I REALIZE THIS ISN'T THE BEST PRACTICE BUT IT'S DUE TO CENTERED LOGO, 2 SEPARATE MENU BLOCKS AND WF MOBILE MENU WIDGET CONSTRAINTS */
@media screen and (max-width: 991px) {
	.nav-menu.nav-left {
		display: none !important;
	}

	.nav-menu.nav-right {
		display: none !important;
	}

	.nav-bar-outer-wrap {
		height: auto;
	}
}

/*mmenu*/
.mobile-navigation-menu {
	display: none !important;
}

@media (max-width: 991px) {
	.mobile-navigation-menu.mm-opened {
		display: block !important;
		width: 80% !important;
		margin: 0;
		max-width: none;
		min-width: 0;
	}
}

html.mm-right.mm-opening .mm-menu ~ .mm-slideout {
	-webkit-transform: translate(-80%, 0);
	-ms-transform: translate(-80%, 0);
	transform: translate(-80%, 0);
	-webkit-transform: translate3d(-80%, 0, 0);
	transform: translate3d(-80%, 0, 0);
}

.mm-opened .menu-button.w-nav-button {
	display: block;
	background-color: transparent;
	color: #fff200;
}

.mm-panels > .mm-panel > .mm-listview {
	margin: 0;
}

.nav-menu.mobile-nav-menu {
	z-index: 0;
}

html.mm-right.mm-opening .mm-slideout {
	transform: translate(-80%, 0px);
	z-index: 1;
}

/*remove default link styles*/
.mm-listview > li > a,
.mm-listview > li > span {
	margin-left: 2px;
	margin-right: 2px;
	padding: 0.75rem 12px;
	font-size: 1rem;
	line-height: 1.4rem;
	letter-spacing: 0.05rem;
	font-weight: 700;
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	text-transform: uppercase;
	text-align: left;
	text-decoration: none;
	border-bottom: 1px solid #141313;
}

.mm-menu .mm-listview > li.active > a:not(.mm-next),
.mm-menu .mm-listview > li.active > span,
.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span {
	color: #fff200;
	background-color: transparent;
	font-size: 1rem;
}

.mm-listview > li > a:not(.mm-next):hover,
.mm-menu .mm-listview > li > span:hover {
	color: #fff200;
	font-size: 1rem;
	border-bottom: 1px solid #141313;
}

/*remove default borders*/
.mm-listview > li:not(.mm-divider)::after {
	border-bottom-width: 0;
}

/*next links*/
.mm-listview > li .mm-next::before {
	border-left-color: transparent;
	border-left: 1px solid #141313 !important;
}

.mm-listview .mm-next {
	width: 12%;
	border-bottom: 1px solid #141313 !important;
	background-color: transparent;
}

.mm-listview .mm-next + a {
	width: 88%;
	margin-right: 12%;
}

/*next link arrow*/
.mm-next::after,
.mm-arrow::after {
	right: 45%;
	height: 12px;
	width: 12px;
}

.mm-menu .mm-listview > li .mm-next::after,
.mm-menu .mm-listview > li .mm-arrow::after {
	border-color: #fff;
}

.mm-menu .mm-listview > li .mm-next:hover::after,
.mm-menu .mm-listview > li .mm-arrow:hover::after {
	border-color: #fff200;
}

/*second level and previous links*/
.mm-prev::before {
	left: 17px;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -12px;
}

.mm-panels .mm-panel:first-of-type .mm-listview {
	margin-top: -55px;
}

.mm-navbar {
	height: 47px;
	box-shadow: none;
	border-bottom: 1px solid #141313 !important;
	margin-left: 20px;
	margin-right: 20px;
	color: #fff;
	text-align: left;
	padding: 0 32px;
}

.mm-navbar .mm-btn {
	height: 47px;
}

.mm-menu .mm-navbar .mm-btn::before,
.mm-menu .mm-navbar .mm-btn::after {
	border-color: #fff;
}

.mm-menu .mm-navbar:hover .mm-btn::before,
.mm-menu .mm-navbar:hover .mm-btn::after {
	border-color: #fff200;
}

.mm-navbar .mm-title {
	color: #fff !important;
	padding: 0.75rem 0;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: 0.05rem;
	font-weight: 700;
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	text-transform: uppercase;
}

.mm-navbar:hover .mm-title {
	font-size: 1rem;
	color: #fff200 !important;
}

/* breakpoints */
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 992px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 768px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/*inside pages*/
/*messages*/
.success {
	display: block;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #b5eebe;
	font-size: 1.125rem;
}

.error {
	display: block;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #f5d1d1;
	font-size: 1.125rem;
}

.success p,
.success ul li,
.error p,
.error ul li {
	margin-bottom: 0;
	margin-top: 0;
	font-family: Roboto, sans-serif;
	color: #424242;
	font-size: 1.125rem;
	line-height: 1.6rem;
	font-weight: 400;
}

/*tables*/
table.styled {
	width: 100%;
	margin: 20px 0 10px 0;
	background-color: white;
	border-spacing: 0px;
}

table.styled ~ p {
	clear: both;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	padding: 10px 10px;
	text-align: left;
	background-color: black;
	color: white;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	padding: 10px 10px;
	text-align: left;
	background-color: darkgray;
	color: black;
}

table.styled td {
	padding: 8px 10px;
}

table.styled.striped tr:nth-child(even) {
	background: lightgray;
}

table.styled.striped tr:nth-child(odd) {
	background: transparent;
}

/*images*/
img {
	height: auto;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*forms*/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

.label div,
.cms_label div {
	font-size: 0.8571rem;
	line-height: 1.5rem;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
textarea,
select {
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 4px 10px;
	border: 1px solid #878787;
	color: #333333;
}

textarea {
	resize: vertical;
}

/*blockquotes*/
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider {
	height: 1px !important;
	margin-top: 1.5em !important;
	margin-bottom: 1.5em !important;
	background-color: #cccccc !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

hr:not(.fc-divider):not(.formBreak) {
	border-color: #cccccc;
	border: 0px;
	color: #cccccc;
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/* ************************************ feature pages ************************************ */
/* ****************** accounts ****************** */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 360px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
	text-align: center;
}

#account_module.form .form_item.required .field {
	background: transparent none;
}

#account_module.form .form_item .field {
	padding-left: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #cccccc;
	margin: 20px 0;
	padding-bottom: 7px;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 2rem;
	line-height: 2.7rem;
	font-weight: 400;
	color: #424242;
	padding: 0;
}

#account_module.form .form_container .form_item .label {
	text-align: right;
	padding-right: 15px;
	vertical-align: top;
}

#account_module.form .form_container .form_item.required .label::after {
	content: "*";
	color: #eb0000;
	padding-left: 5px;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #eb0000;
	font-style: italic;
}

#account_module.form .more_options ul {
	list-style-type: none;
}

/*password assistance*/
#account_module.form .required_message span {
	background-image: none;
}

#account_module.form .required_message span::before {
	content: "*";
	color: #eb0000;
	padding-right: 5px;
}

@media (max-width: 991px) {
	#account_module.form .form_container .form_item .label {
		text-align: left;
	}

	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* ****************** eCommerce ****************** */
/*list view*/
#products_module.responsive .items .item-wrapper .item .item-description .metadata1.cms_title h3 {
	line-height: inherit;
}

#products_module.responsive a {
	border-bottom: 1px dashed #ccc;
	color: #c9353c;
}

#products_module.responsive a.social-share-link {
	border-bottom: none;
	transition: unset;
}

#products_module.responsive.cms_list .item-description .cms_title a {
	word-wrap: break-word;
}

#products_module.responsive.cms_list .item-description .item-short-description {
	word-wrap: break-word;
}

#products_module.responsive a:hover {
	border-bottom: 0 none;
	background-color: #dedfe4;
}

#products_module.responsive a.social-share-link:hover {
	background-color: transparent;
	visibility: visible;
}

#products_module.responsive .item-image a,
#products_module.responsive .pagination-filter a {
	border-bottom: 0 none;
}

#products_module.responsive .item-image a:hover,
#products_module.responsive .pagination-filter a:hover {
	background-color: transparent;
	visibility: visible;
}

#products_module.responsive .items.grid-view .item-wrapper .item .item-image a {
	text-align: left;
	height: auto;
	line-height: 1;
	margin-bottom: 10px;
}

.item-prices {
	font-weight: 700;
}

.pagination-wrapper .pagination-current-page select {
	padding: 4px 10px;
}

#products_module.responsive .items .item-wrapper .item .item-image {
	padding: 0;
	border: 0 none;
}

#products_module.responsive .items.grid-view .item-wrapper .item.clickable-item.tile-layout-1 {
	margin: 20px;
}

/*register form*/
#s-customer-register fieldset .ui-form-legend .ui-form-legend-text {
	line-height: 1rem;
	color: #ffffff;
	display: block;
}

#s-customer-register em {
	color: #eb0000;
	font-style: italic;
}

/*account addresses form*/
.ui-form-select > select,
.ui-form-textarea > textarea,
.ui-form-text > input[type="password"],
.ui-form-text > input[type="text"] {
	width: 100% !important;
	max-width: 348px;
}

#account_module.form #PhoneExtension {
	width: 40px !important;
}

/*review and question forms*/
#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label {
	font-weight: 400;
	font-size: 0.8571rem;
	line-height: 1.5rem;
}

#products_module.responsive.cms_form .ui-form-field legend {
	width: 100%;
}

#products_module.responsive.cms_form em {
	color: #eb0000;
	font-style: italic;
}

/*details view*/
#products_module.responsive .add_to_cart_form button {
	margin-bottom: 0.5rem;
}

/*cart*/
#s-cart #s-promotions .s-button,
#s-cart #s-promotions #s-button {
	margin-left: 0;
}

#s-cart .w-col.s-summary #s-estimate-shipping button {
	margin: 0;
	min-width: 150px;
}

#s-wishlist .s-add-to-cart-links button span,
#s-favorites .s-add-to-cart-links button span {
	background: 0 none;
	padding-right: 0;
}

/*checkout*/
#s-checkout em {
	color: #eb0000;
	font-style: italic;
}

.ui-form-field-subtitle .ui-form-label label,
.ui-form-field-subtitle .ui-form-label span {
	font-weight: 400;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	color: #424242;
}

#s-customer #s-sign-in .ui-form-label {
	height: auto;
}

#s-customer em {
	font-style: italic;
	color: #eb0000;
}

.form-shipping-info .ui-form-buttons,
.form-payment-info .ui-form-buttons {
	padding: 0 !important;
}

#s-checkout #s-steps-bar ul li a:hover {
	visibility: visible;
	background-color: transparent;
}

#s-customer-register fieldset legend.ui-form-legend-wrapper .ui-form-legend {
	background-color: #3e3939;
}

/*order confirmation*/
#s-order-confirmation button {
	margin-right: 0;
}

@media (max-width: 767px) {
	/*review and question forms*/
	#products_module.responsive.cms_form .ui-form-field {
		width: 100% !important;
	}
	/*account addresses form*/
	#account_addresses .address_section td {
		display: block;
		width: 100%;
	}

	#account_addresses .address_section td br {
		display: none;
	}
}

/* ****************** faqs ****************** */
/*list view*/
#faqs_module .cms_list_item ul,
#faqs_module .cms_list_subitem ul {
	color: #424242;
	list-style-type: disc;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	list-style-type: none;
	padding-top: 0;
}

#faqs_module.cms_list .cms_list_item:first-of-type {
	padding-top: 0;
}

#faqs_module.cms_list .cms_list_item .cms_title h3,
#faqs_module.cms_list .cms_list_subitem .cms_title h3 {
	margin-top: 0;
}

/*right sidebar*/
.box.tools .content .contentbox_item form input[type="text"] {
	width: 100%;
}

.box.tools .content .contentbox_item form > p {
	margin-bottom: 0;
}

/* ****************** posts ****************** */
/*list view*/
.collection > .node {
	border-top: 1px solid #cccccc;
	margin: 1.5em 0 0 0;
	padding: 1.5em 0 0 0;
}

.collection > .node:first-child {
	border: none;
	margin-top: 0;
	padding-top: 0;
}

.collection.posts-collection .node.posts-node h2 {
	margin-top: 0;
	margin-bottom: 5px;
}

/*details view*/
.posts-node .post-comments-title {
	margin-top: 1em;
}

.posts-node .post-comment {
	border-bottom: 1px solid #cccccc;
	padding: 1em 0;
}

.posts-node .post-comment-author {
	font-size: 0.825rem;
}

.field p {
	margin-bottom: 1rem;
	margin-top: 0;
}

/*right sidebar*/
.posts-blocks-rss-feed-link,
.posts-blocks-subscribe a,
.posts-blocks-tags a {
	border-bottom: 0 none !important;
}

.posts-blocks-rss-feed-link:hover,
.posts-blocks-subscribe a:hover {
	visibility: visible;
	background-color: transparent !important;
}

.posts-blocks.posts-blocks-searches,
.posts-blocks.posts-blocks-rss-feeds,
.posts-blocks.posts-blocks-subscribes {
	border-top: 0 none;
	margin-bottom: 0;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form input.posts-blocks-search-field[type="text"] {
	width: calc(100% - 44px);
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	height: auto;
}

/* ****************** submission forms ****************** */
/*form views*/
#submissionforms_module.cms_form .cms_divider {
	display: none;
}

#submissionforms_module.cms_form .formSection,
.module-forms .formSection {
	margin: 1.5em 0;
}

#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	border: 0 none;
	border-top: 1px solid #cccccc;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input {
	display: block;
}

#submissionforms_module.cms_form .ui-datepicker-trigger {
	top: 0px;
}

/*multi forms*/
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding: 1px 27px;
}

#submissionforms_module.cms_form .cms_steps_wrapper span a {
	border-bottom: 0 none;
}

/*review submission view*/
.module-forms.confirmation .step-title {
	margin-top: 0.5em;
	font-family: 'Roboto Slab', sans-serif;
	font-size: 2rem;
	line-height: 2.7rem;
	font-weight: 400;
	padding: 0;
	color: #424242;
}

.module-forms.confirmation .label-cell {
	font-weight: 500;
}

@media (max-width: 991px) {
	/*review submission view*/
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}

	.module-forms.confirmation .label-cell {
		padding-bottom: 0;
	}

	.module-forms.confirmation .value-cell {
		padding-top: 0;
	}
}

@media (max-width: 767px) {
	/*form views*/
	#submissionforms_module tr[data-required="true"] .cms_label::after {
		content: "";
		padding: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label label::after {
		content: "*";
		color: #eb0000;
		padding-left: 5px;
	}

	#submissionforms_module tr[data-required="true"] .cms_label div::after {
		content: "*";
		color: #eb0000;
		padding-left: 5px;
	}
}

/* ****************** surveys ****************** */
.module-forms.confirmation .label-cell {
	font-weight: 700;
}

#form_order_delivery_survey > table > tbody > tr {
	border-bottom: 1px solid #ccc;
}

#form_order_delivery_survey > table > tbody > tr > td {
	padding-bottom: 1.5em !important;
	padding-top: 1.5em !important;
}

#form_order_delivery_survey > table > tbody > tr:last-of-type {
	border-bottom: 0 none;
}

#form_order_delivery_survey > table > tbody > tr:last-of-type > td {
	padding-bottom: 0 !important;
	padding-top: 0 !important;
}

/* ****************** videos ****************** */
#video_module.cms_list .cms_list_item .cms_video {
	padding-right: 20px;
}

#video_module.cms_list .cms_list_item .cms_date {
	font-weight: 700;
}

#video_module.cms_list .cms_list_item {
	border-bottom: 1px solid #ccc;
	padding-bottom: 1.5em;
	margin-bottom: 1.5em;
}

#video_module.cms_list .cms_list_item:last-of-type {
	border-bottom: 0 none;
	padding-bottom: 0;
	margin-bottom: 0;
}

@media (max-width: 767px) {
	#video_module.cms_list .cms_list_item td {
		display: block;
	}

	#video_module.cms_list .cms_list_item td.cms_video {
		padding-bottom: 10px;
	}
}

/* ************************************ feature content boxes ************************************ */
/* ****************** eCommerce ****************** */
#products_module.homepage_contentbox {
	margin-top: 1rem;
}

#products_module.homepage_contentbox a,
#products_module.featured_list a {
	font-size: 1.05rem;
	line-height: 1.7rem;
	font-weight: 400;
	text-decoration: none;
	color: #c9353c;
	border-bottom: 1px dashed #ccc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

#products_module.homepage_contentbox a:hover,
#products_module.featured_list a:hover {
	border-bottom-style: dashed;
	border-bottom-color: transparent;
	background-color: #dedfe4;
}

#products_module.homepage_contentbox .metadata1.name {
	line-height: 1.7rem;
	margin-top: 0.5rem;
}

#products_module.homepage_contentbox .cms_content > a {
	text-transform: capitalize;
}

#products_module.homepage_contentbox .cms_list_item_grid_container {
	padding-right: 10px;
	padding-bottom: 10px;
	text-align: center;
}

#products_module.homepage_contentbox + .button.cb-flex-button,
#products_module.featured_list + .button.cb-flex-button {
	display: none;
}

/* ****************** posts ****************** */
.home-content-section .collection.posts-collection p {
	margin-right: 0;
	margin-left: 0;
	margin-bottom: 0;
}

.home-content-section .collection.posts-collection .field.posts-field a {
	font-weight: 400;
	text-decoration: none;
	color: #c9353c;
	border-bottom: 1px dashed #ccc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.home-content-section .collection.posts-collection .field.posts-field a:hover {
	border-bottom-style: dashed;
	border-bottom-color: transparent;
	background-color: #dedfe4;
}

.home-content-section .collection.posts-collection + .button.cb-flex-button {
	display: none;
}

.posts-collection.homepage-contentbox.article-items {
	margin-bottom: 0;
}

.posts-collection.homepage-contentbox.article-items .article {
	padding-left: 0;
	padding-right: 0;
}

.posts-collection.homepage-contentbox.article-items .article-title {
	margin-top: 5px;
	margin-bottom: 5px;
}

.posts-collection.homepage-contentbox.article-items .article-title-link {
	font-size: 1.05rem;
	line-height: 1.7rem;
	font-weight: 400;
	text-decoration: none;
	color: #c9353c;
	border-bottom: 1px dashed #ccc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.posts-collection.homepage-contentbox.article-items .article-title-link:hover {
	border-bottom-style: dashed;
	border-bottom-color: transparent;
	background-color: #dedfe4;
}

.posts-collection.homepage-contentbox.article-items .article-date {
	margin-top: 0;
	font-weight: 700;
}

.posts-collection.homepage-contentbox.article-items ~ .button.cb-flex-button {
	display: none;
}

/* ****************** submission forms ****************** */
#submissionforms_module.homepage_contentbox {
	width: 100%;
	text-align: left;
}

#submissionforms_module.homepage_contentbox .cms_divider {
	display: none;
}

#submissionforms_module.homepage_contentbox .cms_label span {
	font-size: 0.8571rem;
	line-height: 1.5rem;
}

#submissionforms_module.homepage_contentbox label {
	display: inline;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: 100%;
}

#submissionforms_module.homepage_contentbox .cms_field input.hasDatepicker[type="text"] {
	width: calc(100% - 20px);
}

#submissionforms_module.homepage_contentbox + script + .home-content-box-learn-more {
	display: none;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	display: block;
	color: #221f1f;
}

#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary {
	width: 100%;
}

#submissionforms_module.homepage_contentbox ~ .button.cb-flex-button {
	display: none;
}

@media (max-width: 767px) {
	#submissionforms_module.homepage_contentbox .cms_label em {
		display: none;
	}
}

/* ****************** videos ****************** */
.homepage-featured-video {
	float: none;
	clear: both;
	width: 100%;
	position: relative;
	padding-bottom: 350px;
	padding-top: 25px;
	height: 0;
}

.homepage-featured-video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.homepage-featured-video + .button.cb-flex-button {
	display: none;
}

@media (max-width: 479px) {
	.homepage-featured-video {
		padding-bottom: 250px;
	}
}

#shipping-addresses .s-address-row-right .button,
#shipping-addresses .s-address-row-right .primary,
#shipping-addresses .s-address-row-right .secondary,
#shipping-addresses .s-address-row-right .tertiary,
#shipping-addresses .s-address-row-right button[type='submit'] {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

/* temp */
.s-overlay .s-overlay-loading {
	z-index: 999;
}


#s-checkout .form-shipping-info .ui-form-buttons,
#s-checkout .form-payment-info .ui-form-buttons {
	padding: 0 0 0 240px !important;
}

@media ( max-width: 479px) {
	#s-checkout .form-shipping-info .ui-form-buttons,
	#s-checkout .form-payment-info .ui-form-buttons {
		padding: 0 !important;
	}
}

/*share this section*/
.share-expert-tips {
	padding-top: 2rem;
}

.social-share {
	margin: 0 auto;
	padding-bottom: 30px;
}

.social-share h3 {
	margin-top: 0;
	margin-bottom: 10px;
	text-align: center;
	font-size: 1.25em;
	text-transform: uppercase;
	line-height: 1.25em;
	font-weight: 400;
	color: #424242;
}

.social-share-list {
	list-style-type: none;
	margin: 0 auto;
	padding-left: 0;
	display: flex;
	justify-content: center;
}

.social-share-link {
	display: block;
	margin: 0 8px;
	height: 34px;
	width: 34px;
	background-size: 34px 68px;
	background-position: 0 0;
	background-repeat: no-repeat;
	transition: none;
}

.social-share-link:hover {
	background-position: 0 100%;
}

.share-facebook {
	background-image: url("/20160710165515/assets/images/share-facebook-sprite.svg");
}

.share-twitter {
	background-image: url("/20160710165515/assets/images/share-twitter-sprite.svg");
}

.share-pinterest {
	background-image: url("/20160710165515/assets/images/share-pinterest-sprite.svg");
}

.share-email {
	background-image: url("/20160710165515/assets/images/share-email-sprite.svg");
}

.align-everything-left .social-share h2 {
	text-align: left;
}

.align-everything-left .social-share-list {
	justify-content: left;
	margin: 0;
}

.align-everything-left .social-share-link {
	margin: 0 16px 0 0;
}

/*pagination*/
.pagination-next-page a::after,
.pagination-next-page span::after {
	content: "\00bb";
	padding-left: 5px;
}

.pagination-previous-page a::before,
.pagination-previous-page span::before {
	content: "\00ab";
	padding-right: 5px;
}

.pagination-next-page a,
.pagination-previous-page a {
	border-bottom: 0 none;
	color: #424242;
	font-weight: 700;
	font-size: 0.9rem;
}

.pagination-next-page a:hover,
.pagination-previous-page a:hover {
	font-weight: 700;
	background-color: transparent;
	color: #c9353c;
}

/* This is a gift changes */
.gift-options-container .ui-form-checkbox {
	display: flex;
	align-items: center;
}

.gift-options-container .ui-form-checkbox label {
	font-size: 1rem;
}

.gift-options-container .ui-form-checkbox input {
	margin: 0 15px;
}

#s-cart .w-col.s-summary #s-buttons {
	padding-top: 0;
	padding-bottom: 10px;
}

.gift-options-container .ui-form-textarea span.gift-message-note {
	line-height: 1.0625rem;
	font-size: 0.7rem;
	display: block;
}

.gift-options-container .ui-form-textarea {
	padding-top: 10px;
}

.form-shipping.form-shipping-info.bypassProcessing .ui-form-buttons.ui-form-field-shipping.s-wide-button button#ShippingContinueButton {
	margin: 0;
}

.gift-options-container .ui-form-checkbox span.gift-icon-placeholder {
	background: url('/20180123158547/assets/images/gift-2c-32px.png');
	width: 30px;
	height: 30px;
	background-repeat: no-repeat;
}

/* Expand/Collapse */

.collapsed {
	display: none;
}

.show-more-less {
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: right;
}

.ui-form-credit-card-external-iframe {
	height: 1200px;
}

/*recipes*/
.module-recipes .recipe-divider-new {
	display: none;
}

.module-recipes.controller-pages-recipes .inside-page-wrapper .save,
.module-recipes.controller-pages-recipes .inside-page-wrapper .prep-time,
.module-recipes.controller-pages-recipes .inside-page-wrapper .delete {
	color: #fff;
	text-transform: none;
	font-size: 1rem;
	line-height: 1.6rem;
	border-right: 1px dashed hsla(0, 0%, 80%, 0.5) !important;
	margin: 0;
	flex-direction: column;
}

.module-recipes.controller-pages-recipes.action-show .inside-page-wrapper .save.saved {
	flex-direction: row;
	border-right: 0 none !important;
}

.module-recipes.controller-pages-recipes .inside-page-wrapper .prep-time {
	border-right: 0 none !important;
}

.module-recipes.controller-pages-recipes .inside-page-wrapper .save:hover,
.module-recipes.controller-pages-recipes .inside-page-wrapper .delete:hover {
	color: #fa173d;
	cursor: pointer;
}

.module-recipes .add-ingredient-button {
	height: 38px !important;
}

/* CSS Grid Code */
.grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-bottom: 20px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.grid-box {
	margin-right: 1%;
	margin-bottom: 15px;
	padding: 20px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	box-sizing: border-box;
}

.grid-box.half {
	width: 49%;
}

.grid-box.third {
	width: 32.33%;
}

.grid-box.quarter {
	width: 24%;
}

.grid-box.fifth {
	width: 19%;
}

.grid-box.twothirds {
	width: 65.66%;
}

.grid-box.center {
	text-align: center;
}

.grid-box.center p {
	margin: 0 auto;
	max-width: 400px;
}

.grid-box img {
	max-width: 100%;
	height: auto;
}

@media (max-width: 991px) {
	.grid-box.quarter {
		width: 49%;
	}

	.grid-box.fifth {
		width: 49%;
	}
}

@media (max-width: 767px) {
	.grid-box.half {
		width: 100%;
	}

	.grid {
		margin-left: -20px;
		margin-right: calc(-20px + 1%);
	}

	.grid-box.twothirds {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box-1 {
		order: 2;
	}

	.grid-box-2 {
		order: 1;
	}
}

@media (max-width: 630px) {
	.grid-box.third {
		order: -1;
	}
}

@media (max-width: 479px) {
	.grid-box {
		margin-right: 0%;
	}

	.grid-box.half {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box.quarter {
		width: 100%;
	}

	.grid-box.fifth {
		width: 100%;
	}
}

/* grid styles */
.grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-bottom: 20px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.grid-box {
	margin-right: 1%;
	margin-bottom: 15px;
	padding: 20px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	box-sizing: border-box;
}

.grid-box.half {
	width: 49%;
}

.grid-box.third {
	width: 32.33%;
}

.grid-box.quarter {
	width: 24%;
}

.grid-box.fifth {
	width: 19%;
}

.grid-box.twothirds {
	width: 65.66%;
}

.grid-box.center {
	text-align: center;
}

.grid-box.center p {
	margin: 0 auto;
	max-width: 400px;
}

.grid-box img {
	max-width: 100%;
	height: auto;
}

@media (max-width: 991px) {
	.grid-box.quarter {
		width: 49%;
	}

	.grid-box.fifth {
		width: 49%;
	}
}

@media (max-width: 767px) {
	.grid-box.half {
		width: 100%;
	}

	.grid {
		margin-left: -20px;
		margin-right: calc(-20px + 1%);
	}

	.grid-box.twothirds {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box-1 {
		order: 2;
	}

	.grid-box-2 {
		order: 1;
	}
}

@media (max-width: 630px) {
	.grid-box.third {
		order: -1;
	}
}

@media (max-width: 479px) {
	.grid-box {
		margin-right: 0%;
	}

	.grid-box.half {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box.quarter {
		width: 100%;
	}

	.grid-box.fifth {
		width: 100%;
	}
}

#ffPlaceOrderButton {
	text-align: end;
}

.module-ecommerce-cart.action-checkout .ui-dialog {
	left: 50% !important;
	top: 50% !important;
	transform: translate(-50%, -50%);
}

@media ( max-width: 767px ) {
	.module-ecommerce-cart.action-checkout .ui-dialog {
		width: 100% !important;
	}
}


#products_module.responsive.cms_entity .related-items .related-items-title,
#products_module.responsive.cms_entity .recently-viewed-items .recently-viewed-items-title {
	font-weight: normal;
	color: #c9353c;
	font-size: 0.9rem;
	font-family: 'Roboto Slab', sans-serif;
}
